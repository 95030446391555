@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3;
    /* Light grey */
    border-top: 10px solid #783998;
    /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;

}

/* .loading-spinner,
  .spinner-container p {
    position: relative;
    top: 50%;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%, -50%);
  } */

.spinner-container {
    height: 100vh;
    position: fixed;
    backdrop-filter: blur(2px);
    z-index: 99999;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}