body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.fw-500 {
  font-weight: 500;
}

/*=====body start=====*/
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  font-family: "Raleway", sans-serif;
  color: #333333;
  font-weight: 600;
}

body h1 {
  font-size: 45px;
}

body h2 {
  font-size: 35px;
}

body h3 {
  font-size: 20px;
}

body h4 {
  font-size: 24px;
}

body h5 {
  font-size: 18px;
  line-height: 21.13px;
}

.bg-purple {
  background-color: #2F0B41;
}

.text-black {
  color: #333333;
}

.cursor-pointer {
  cursor: pointer;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

li {
  list-style: none;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

p {
  font-weight: 400;
  color: #696969;
  font-family: 'poppins', 'sans-serif';
}

.p-lg {
  font-size: 16px;
  line-height: 24px;
}

.p-md {
  font-size: 14px;
  line-height: 21px;
}

.p-sm {
  font-size: 12px;
  line-height: 18px;
}

.p-all-small {
  font-size: 10px;
  line-height: 15px;
}

.section-padding {
  padding: 80px 0px;
}

.form-control {
  border: 1px solid #DDDDDD !important;
  border-radius: 5px !important;
  padding: 9px 20px !important;
}

.btn-light-disabled {
  background-color: #DDDDDD !important;
  width: 100%;
  color: #fff !important;
}

.text-grey-light {
  color: #696969 !important;
}

.text-color-theme {
  color: #333333 !important;
}

.text-purple {
  color: #6D338A !important;
}

.p-large {
  font-size: 20px;
  line-height: 23.48px;
}

.ylw-theme-button {
  background-color: #F7BD59;
  padding: 7.5px 18px;
  border-radius: 5px;

  &:hover {
    background-color: #e6970d;
  }
}

.font-family-poppins {
  font-family: 'poppins';
}

.form-check-input:checked {
  background-color: #6D338A !important;
  border-color: #6D338A !important;
}

.ylw-button {
  background-color: #F7BD59;
  color: #333333;
  padding: 8px 19px 10px;
  border-radius: 5px;
  transition: all .5s;
}

.ylw-button:hover {
  background-color: #e89a14;
  transition: all .5s;
}

.ylw-text {
  color: #F7BD59 !important;
}

.text-green {
  color: #36AB0D !important;
}

.white-border-btn {
  /* border: 1px solid !important; */
  padding: 10px 20px !important;
  border-radius: 5px;
  /* border-image: linear-gradient(to bottom right, #ffffff -15%, #ffffff 6%, #ffffff57 22%) 1 !important; */
  position: relative;


  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 5px;
    border: 1px solid transparent;
    background: linear-gradient(140deg, #ffffff, #ffffff61, #ffffff61, #ffffff61, #ffffff61, #ffffff61) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

}

.booking-form-grid .btn-gradient-purple {
  transform: unset !important;
  padding: 15px 25px !important;
  border: 1px solid #783998;
}

.btn-gradient-purple {
  background: linear-gradient(180deg, #8740AB 0%, #49225C 100%) !important;
  color: #fff !important;
  width: 100%;
  height: 100%;
  font-size: 14px !important;
  padding: 10px 40px !important;
  transition: all .5s;
  box-shadow: 0px -0.3px 0px 1px #582970;
  border-radius: 5px;
  font-weight: 400 !important;
  line-height: 21px !important;
}

.btn-proceed-purple {
  background: linear-gradient(180deg, #8740AB 0%, #49225C 100%) !important;
  color: #fff !important;
  font-size: 14px !important;
  padding: 10px 40px !important;
  transition: all .5s;
  box-shadow: 0px -0.3px 0px 1px #582970;
  border-radius: 5px;
  font-weight: 400 !important;
  line-height: 21px !important;
}

.btn-gradientlg-purple {
  background: linear-gradient(180deg, #8740AB 0%, #49225C 100%) !important;
  color: #fff !important;
  width: 100%;
  height: 100%;
  font-size: 14px !important;
  padding: 10px 40px !important;
  transition: all .5s;
  box-shadow: 0px -0.3px 0px 1px #582970;
  border-radius: 5px;
}

.btn-gradient-purple:hover,
.btn-gradientlg-purple:hover {
  background: linear-gradient(180deg, #49225c 0%, #49225c 100%) !important;
  transition: all .5s;
}

.btn-gradient-purple-light,
.btn-border-btn-filter.active {
  background: #F6E4FF !important;
  color: #333333 !important;
  position: relative;
}

.left-filter-wrapper .btn-border-btn-filter.active::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 5px;
  border: 1px solid transparent;
  background: linear-gradient(140deg, #54276a 1%, #612d7b45, #612d7b45, #612d7b45, #612d7b45, #612d7b45) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}




/*=====body end=========*/


/*====header start======*/
.currency-translater-header .dropdown-mine:focus {
  border-color: transparent;
}

.currency-translater-header .dropdown-mine::after {
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  width: 10px;
  height: 10px;
  border-top: 0px;
  transform: rotate(314deg);
  margin-left: 10px;
}

.header .navbar-header li a {
  margin: 0px 10px;
  font-size: 14px;
}

.header .navbar-header li:last-child a {
  margin-right: 0;
}

.language-translater .active-language {
  background: #F7BD59;
  color: #6D338A !important;
  padding: 3px 15px;
  border-radius: 100px;
  font-size: 12px;
}

.language-translater .translator_list {
  border: 1px solid #74268F;
  border-radius: 100px;
  padding: 5px 5px;
}

.currency-translater-header .dropdown-mine {
  font-size: 14px;
  background: transparent;
  border: 0px;
}


/*====header end======*/

/*====banner start====*/
.flight-banner-wrapper {
  background-image: url('assets/Banner.png');
  background-size: cover;
  background-position: 20% 90%;
  position: relative;
}

.booking-flight-inner .tabs-flight .nav-link.active {
  background: #00000012 !important;
  border: 1px solid #ffffff !important;
  border-radius: 5px !important;
}

.flight-tab-spacer {
  padding-bottom: 36px !important;
}

.revert-icon {
  border-top: 1px solid #dddd;
  border-bottom: 1px solid #dddd;
  width: 24px;
}

.destination-box.flight-input-two0 input {
  padding-left: 22px;
}

.tabs-flight .tabs-btn {
  padding: 10px 17px;
  font-family: 'Raleway';
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin: 0px 5px;
}

.booking-flight-inner {
  padding: 38px 0px 0px;
}

.booking-tab-form .destination-box .react-datepicker-wrapper {
  width: 100%;
}

.booking-form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  gap: 6px;
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  width: max-content;
  margin: 0 auto;
  width: 80%;
  align-items: center;
}

.booking-tab-form .destination-box {
  position: relative;
}

.destination-box input,
.destination-box {
  cursor: pointer;
}

.destination-box.first-box.item-one input,
.destination-box.first-box.item-one {
  cursor: auto;
}

.booking-tab-form .destination-box img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5%;
  width: 22px;
  z-index: 1;
}

html[dir="rtl"] .booking-tab-form .destination-box img {
  left: auto;
  right: 5%;
}


.booking-tab-form .destination-box img.calendar-icon {
  width: 18px;
}

/* .booking-tab-form .destination-box.first-box input,
.booking-tab-form .destination-box.first-box select {
  padding: 20px 10px 20px 45px;
  line-height: 20px;
  border: 0px;
} */
/* 
.booking-tab-form .destination-box.second-box input {
  padding: 20px 17px 20px 35px;
  line-height: 18px;
} */

.booking-tab-form .destination-box select {
  --bs-form-select-bg-img: none;
}

.booking-tour-grid {
  grid-template-columns: 1fr 17% 25%;
  gap: 6px;
}

.booking-tour-grid .destination-box img {
  left: 3%;
}

html[dir="rtl"] .booking-tour-grid .destination-box img {
  left: auto;
  right: 3%;
}

/* .booking-tour-grid .destination-box.first-box img {
  left: 35px;
} */

.flight-tab-wrapper .flight-list-ul .nav-link {
  border: 0px;
  border-radius: 5px;
  padding: 3px 8px;
  font-size: 14px;
  line-height: 23px;
  border: 1px solid transparent;
}

.flight-tab-wrapper .flight-list-ul .nav-link:hover {
  border-color: transparent;
}

/* .destination-box.flight-input-two {
  margin-bottom: 1px !important;
} */


.flight-tab-wrapper .flight-list-ul .nav-link.active {
  background-color: #6D338A !important;
  border: 1px solid transparent;
}

.destination-box.flight-input-two img {
  left: 4%;
}


html[dir="rtl"] .booking-tab-form .destination-box input.form-control,
html[dir="rtl"] .booking-tab-form .destination-box select {
  padding: 15px 50px 15px 25px !important;
  text-align: right;
}

.booking-tab-form .destination-box input.form-control,
.booking-tab-form .destination-box select {
  padding: 15px 25px 15px 50px !important;
  font-size: 14px;
  color: #696969;
  border-radius: 5px !important;
  border-color: #dddd !important;
  /* height: 100%; */
  text-align: left;
}

/* .booking-tab-form .destination-box:hover input.form-control,
.booking-tab-form .destination-box:hover select,
:where(.css-dev-only-do-not-override-7ny38l).ant-picker-outlined:hover {
  border-color: #783998 !important;
} */

.booking-fourth-grid {
  grid-template-columns: 1fr 23% 23%;
}

.booking-tab-form .destination-box input:focus {
  box-shadow: unset;
}

.tab-grid-onewy {
  border: 1px solid #dddd;
  border-radius: 5px;
  /* margin-bottom: 12px; */
}

.inner-banner-wrapper {
  position: relative;
  padding: 30px 0px 55px;
}

.booking-fourth-grid .destination-box img {
  left: 4%;
}

.inner-banner-wrapper .banner_time img {
  position: absolute;
  right: 0;
  bottom: -40px;
}

.booking-tab-form .destination-box input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  opacity: 0.5;
  left: 18px;
}

/* .booking-tab-form .destination-box.search-btn {
  margin-left: 8px;
} */

/* .destination-box.second-box.marrgin-left {
  margin-left: 2px;
}

.destination-box.second-box.marrgin-right {
  margin-right: 2px;
} */

.booking-form-grid.flight-oneway-grid {
  grid-template-columns: 35% 20% 20% 25%;
}

.flight-list-ul {
  margin-bottom: 6px !important;
}

.booking-flight-inner .tabs-flight .nav-link img,
.flight-bottom-txt img {
  width: 14px !important;
  height: auto !important;
}

/*====banner end====*/

/*====featurs section start===*/
.features-wrapper {
  padding: 80px 0px 46px;
}

/*==features section end====*/

/*====hotels section start=====*/

.top-hotels::before {
  content: "";
  background: #e4d9ea;
  width: 100px;
  height: 100px;
  backdrop-filter: blur(416px);
  position: absolute;
  bottom: 0;
  border-radius: 50%;
  box-shadow: 0px 0px 137px #6D338A;
}

.card-hotel-wrapper .card-img-hotel {
  border-radius: 10px;
  height: 350px;
  object-fit: cover;
}

.card-hotel-wrapper .card-content {
  border-radius: 10px;
  box-shadow: 0px 4px 15px 0px #0000001A;
  left: 0;
  right: 0;
  width: 85%;
  margin: 0 auto;
  bottom: 6%;
  padding: 12px 16px;
}

.reviews-box .star-rating li i {
  font-size: 12px;
  color: #FFCE00;
}

.card-hotel-wrapper .rating-txt {
  font-size: 10px;
  font-family: 'Poppins';
  line-height: 15px;
}

.offer-card-wrapper .offer-card-box p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

/*====hotels section end=====*/

/*====topcities section start=====*/

.cities-col-shadow .card::before {
  content: "";
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.3) 85%, rgba(0, 0, 0, 0.6) 100%);
  bottom: 0;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.top-cities .city-txt-wrap {
  left: 0;
  right: 0;
  color: #fff;
  bottom: 20px;
}

.top-cities {
  padding: 55px 0px 40px;
}

/*====topcities section end=====*/

/*=====Offers section start======*/
.offer-card-box .card {
  display: grid;
  grid-template-columns: 35% 1fr;
  box-shadow: 0px 4px 15px 0px #0000001A;
  border-radius: 20px;
  padding: 10px;
  margin: 0px;
}

.offer-card-box img {
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.offer-card-box h5 {
  font-size: 20px;
  line-height: 23.48px;
}

.yellow-txt-link {
  color: #F7BD59;

  &:hover {
    color: #e89c18;
  }
}

.related_rooms_wrapper {
  position: relative;
  overflow: hidden;
  margin: 50px 0px 50px 0px;
}

.related_rooms_wrapper button.slick-arrow,
.offer-card-wrapper button.slick-arrow,
.trending-car-wrapper button.slick-arrow,
.mobile-hotel-details-banner button.slick-arrow,
.hotel-details-modal button.slick-arrow,
.top-cities button.slick-arrow,
.top-hotels-slider-wrap button.slick-arrow {
  width: 22px;
  border: 1px solid #F7BD59;
  border-radius: 50%;
  background: #fff;
  height: 22px;
}

.related_rooms_wrapper button.slick-arrow.slick-prev::before,
.offer-card-wrapper button.slick-arrow.slick-prev::before,
.trending-car-wrapper button.slick-arrow.slick-prev::before,
.mobile-hotel-details-banner button.slick-arrow.slick-prev::before,
.hotel-details-modal button.slick-arrow.slick-prev::before,
.top-cities button.slick-arrow.slick-prev::before,
.top-hotels-slider-wrap button.slick-arrow.slick-prev::before {
  content: "\279C";
  color: #9f9f9f;
  line-height: unset;
  font-size: 14px;
  margin-top: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.related_rooms_wrapper button.slick-arrow.slick-next::before,
.offer-card-wrapper button.slick-arrow.slick-next::before,
.trending-car-wrapper button.slick-arrow.slick-next::before,
.mobile-hotel-details-banner button.slick-arrow.slick-next::before,
.hotel-details-modal button.slick-arrow.slick-next::before,
.top-cities button.slick-arrow.slick-next::before,
.top-hotels-slider-wrap button.slick-arrow.slick-next::before {
  content: "\279C";
  color: #F7BD59;
  line-height: 7px;
  font-size: 14px;
}

.related_rooms_wrapper button.slick-arrow.slick-prev,
.offer-card-wrapper button.slick-arrow.slick-prev,
.trending-car-wrapper button.slick-arrow.slick-prev,
.top-cities button.slick-arrow.slick-prev,
.top-hotels-slider-wrap button.slick-arrow.slick-prev {
  transform: rotate(-180deg);
  left: auto;
  right: 50px !important;
  top: -40px;
  border: 1px solid #9f9f9f;
  opacity: 0.4;
}

.hotel-details-modal button.slick-arrow.slick-prev {
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
}

#slidermodal {
  --bs-modal-bg: auto;
  --bs-modal-border-color: 0px !important;
}

.related_rooms_wrapper button.slick-arrow.slick-prev:hover,
.offer-card-wrapper button.slick-arrow.slick-prev:hover,
.trending-car-wrapper button.slick-arrow.slick-prev:hover,
.mobile-hotel-details-banner button.slick-arrow.slick-prev:hover {
  opacity: 1;
}

.related_rooms_wrapper button.slick-arrow.slick-next,
.offer-card-wrapper button.slick-arrow.slick-next,
.trending-car-wrapper button.slick-arrow.slick-next,
.top-cities button.slick-arrow.slick-next,
.top-hotels-slider-wrap button.slick-arrow.slick-next {
  right: 15px;
  top: -29px;
}

.similar_rooms_wrapper .slick-slider.slick-initialized,
.offer-card-wrapper .slick-slider.slick-initialized,
.trending-car-wrapper .slick-slider.slick-initialized,
.mobile-hotel-details-banner .slick-slider.slick-initialized {
  padding: 0px;
}

.mobile-hotel-details-banner button.slick-arrow.slick-next,
.hotel-details-modal button.slick-arrow.slick-next {
  right: 20px;
}

.mobile-hotel-details-banner button.slick-arrow.slick-prev,
.hotel-details-modal button.slick-arrow.slick-prev {
  left: 20px;
  z-index: 1;
}

.mobile-hotel-details-banner button.slick-arrow.slick-prev {
  transform: rotate(-180deg);
}

/* .related_rooms_wrapper {
  position: relative;
  overflow: hidden;
  padding: 30px 0px 0px;
} */




/*======trending car section start=====*/
.trending-car-content .car-inner-grid,
.car-content-wrapper .car-inner-grid {
  display: grid;
  grid-template-columns: 10% 1fr 10%;
  gap: 10px;
}

.trending-car-img .reviews-box {
  top: 10px;
  right: 12px;
}

.trending-car-content .bottom-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.font-size-15 {
  font-size: 15px;
}

.trending-car-content h5 {
  font-size: 16px;
}

.trending-car-content .bottom-content b {
  color: #333333;
}

.trending-box-content {
  border: 1px solid #DDDDDD;
  padding: 5px;
  border-radius: 10px;
}

.trending-car-content {
  padding: 20px;
}

.month-text {
  font-size: 13px;
}

/*======trending car section end=====*/

/*======Where to travel section start=====*/
.border-box-travel {
  border: 1px solid #DDDDDD;
  border-radius: 10px;
  padding: 20px;
}

.list-style-dots li {
  list-style: disc;
  color: #696969;
}

/*======Where to travel car section end=====*/

/*======Newletter start=====*/
.inner-newslwtter-wrapper {
  background-image: url("./././assets/Rectangle-8.png");
  padding: 90px 280px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
}

.newsletter-form input {
  padding: 18px 30px;
  width: 100%;
  border-radius: 5px;
  border: 0px;
  font-size: 14px;
}

.newsletter-form {
  display: grid;
  grid-template-columns: 1fr 25%;
  gap: 10px;
}

.newsletter-form .newsletter-btn {
  background-color: #F7BD59;
  border: 0px;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;

  &:hover {
    background-color: #e89c18;
  }
}


.newsletter-form input::placeholder {
  color: #b6b5b5;
}

/*======Newletter End=====*/


/*======footer start=====*/

.footer {
  background-color: #6D338A;
  background-image: url("/././src/assets/footer-bg.png");
  background-position: bottom;
  padding: 60px 0px 480px 0px;
  background-size: contain;
  background-repeat: no-repeat;
}

.footer-grid-wrap {
  display: grid;
  grid-template-columns: 2fr 14% 1fr 1fr 1fr;
  gap: 0px 30px;
}

.footer-menu-link h6 {
  color: #F7BD59;
  margin-bottom: 4px;
}

.footer-menu-link ul li {
  font-family: 'Poppins';
  line-height: 21px;
}

.footer-col-two ul li a {
  margin: 0;
}

.footer-col-two ul {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer-links li a {
  margin: 0px 10px;
  font-size: 18px;
}

.copyright-wrapper {
  border-top: 1px solid #dddd;
  padding: 20px 0px;
  margin-top: 40px;
}

/*======footer end=====*/

/*=====Hotel Details banner section start=====*/
.bottom_banner_img img {
  height: 180px;
}

.spacing-ryt {
  padding: 0px 5px;
}

.bottom_banner_img {
  padding-top: 5px;
}

.spacing-equal {
  padding: 0px 0px 5px 5px;
}

.top-home-detail-wrapper.tab-description {
  padding: 40px 0px 20px 0px;
}

.details-room-srch .destination-box input {
  padding: 25px 25px 25px 40px;
  border: 0px;
}

.tabs_tops_spacing {
  padding-top: 40px;
}

.geomap-txt {
  margin-bottom: 22px;
}

.related_rooms_wrapper .slick-slide {
  padding: 0px 13px;
}

.blur-img img {
  filter: blur(4px);
}

.modal-click-btn {
  position: absolute;
  height: auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
  bottom: 0;
}

.slider-image-popup {
  position: relative;
}

.modal-click-btn button {
  height: auto;
  width: auto;
  padding: 8px 40px !important;
}

.hotel-details-modal img {
  border-radius: 4px;
}

.hotel-details-modal .slick-prev {
  left: -40px;
}

.hotel-details-modal .slick-next {
  right: -40px;
}



.hotel-details-modal.show,
.add-travel-modal.show {
  display: flex !important;
  align-items: center;
}

.hotel-details-modal ul.slick-dots,
.offer-card-wrapper ul.slick-dots,
.trending-cars ul.slick-dots,
.related_rooms_wrapper ul.slick-dots,
.mobile-hotel-details-banner,
.mobile-login-signup,
.mobile-translator,
.mobile-translator-wrap {
  display: none !important;
}

.hotel-details-modal .modal-dialog {
  max-width: 1150px;
  width: 100% !important;
}

/*=====Hotel Details banner section end=====*/

/*=====Hotel Details Tabs section start=====*/
.hotel-details-wrapper .page-id-tabs:focus,
.hotel-details-wrapper .page-id-tabs:hover {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
}

.hotel-details-wrapper .page-id-tabs.active {
  color: #6D338A;
  border: 0px;
  border-bottom: 1px solid #6D338A;
}

.hotel-details-wrapper .page-id-tabs {
  padding: 20px 50px;
  color: #333333;
  font-weight: 500;
  font-size: 14px;
  line-height: 21.13px;
  border: 0px;
}

.ryt-col-spacing-tab .row {
  width: 100%;
  margin: 0 auto;
}

/*=====Hotel Details Tabs section end=====*/

/*=====Hotel Details form section start=====*/
.details-room-srch .details_bg_lyt {
  border: 1px solid #DDDDDD;
  background: #F5F5F5;
  border-radius: 10px;
  padding: 20px;
}

.grid-wrapper-form {
  grid-template-columns: 18% 18% 1fr 20%;
  gap: 10px;
}

.details-room-srch .destination-box.first-box img {
  left: 15px;
}

/*=====Hotel Details Form section End=====*/

/*=====Hotel Details Rooms section start=====*/
.related_rooms_wrapper .realted_inner {
  box-shadow: 0px 4px 15px 0px #0000001A;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 38px;
}

.selected-room .realted_inner {
  border: 2px solid purple;
  border-radius: 4%;
}

.related_rooms_wrapper .realted_inner img {
  border-radius: 5px;
}

.related_rooms_wrapper .room-feature ul li {
  color: #696969;
  font-weight: 400;
  font-family: 'Poppins';
  position: relative;
  /* padding-left: 23px; */
  font-size: 12px;
}

/* .related_rooms_wrapper .similar-products-wrapper .room-feature ul li {
  font-size: 10px;
} */


.related_rooms_wrapper .room-feature ul li img {
  /* position: absolute; */
  left: 0;
}

.related_rooms_wrapper .price-room p b,
.yellow-big-txt {
  color: #F7BD59;
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
  margin-right: 8px;
}

.inner-details-cnt h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.related_rooms_wrapper .price-room p b {
  font-size: 22px;
}

.size-big {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.last-font-line .size-big {
  font-weight: 600;
}

.amnenities-wrapper .list-box-amenities {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 20px;
  align-items: center;
}

.amnenities-wrapper .list-box-amenities li p {
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  padding: 8px 15px;
  margin: 0;
}

.amnenities-wrapper .list-box-amenities li p img {
  width: 20px;
}

.policies_box_inner ul li {
  color: #696969;
}

.list-style-dots {
  padding-left: 25px;
}

/* 
.policies_box_inner {
  margin-bottom: 30px;
} */

div#similarpr-tab .slick-track,
div#similarpr-tab .slick-track .slick-slide>div,
div#similarpr-tab .slick-track .slick-slide>div>div,


.related_rooms_wrapper.top-related_rooms_wrapper .slick-track,
.related_rooms_wrapper.top-related_rooms_wrapper .slick-track .slick-slide>div,
.related_rooms_wrapper.top-related_rooms_wrapper .slick-track .slick-slide>div>div {
  height: 100%;
}

div#similarpr-tab .slick-slider {
  display: flex;
}

/*=====Hotel Details Rooms section End=====*/

/*=====Hotel Selected Rooms section Start=====*/
.select-room_inner img {
  width: 110px;
  height: 60px;
  border-radius: 5px;
}

.select-room_inner {
  border: 1px solid #dddd;
  border-radius: 5px;
  padding: 5px 15px 5px 5px;
  box-shadow: 0px 4px 15px 0px #0000001A;
}

.select-room_inner p {
  font-size: 15px;
  line-height: 17.61px;
}

.select-room_inner .dark-txt {
  color: #333333;
  margin-bottom: 3px !important;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
}

.select-room_inner i {
  font-size: 12px;
}

/*=====Hotel Selected Rooms section End=====*/

/*=====payment details start=====*/
#add-person-one-popup form.left-fill-details,
#add-person-second-popup form.left-fill-details {
  height: 70vh;
  overflow: scroll;
  overflow-x: hidden;
}


.left-fill-details input,
.left-fill-details select {
  border: 1px solid #DDDDDD !important;
  padding: 10px 20px !important;
  line-height: 20px !important;
}

.left-fill-details input::placeholder {
  color: #b6b5b5;
  font-size: 14px;
}

.left-fill-details input {
  color: #696969;
  font-size: 14px;
  line-height: 26px;
}

.left-fill-details input[type="radio"] {
  padding: 6px !important;
}

.left-fill-details .form-spacing {
  margin-bottom: 15px;
}

.left-fill-details .form-label {
  margin-bottom: 5px;
}

.payment-details-wrapper,
.card-details-wrapper {
  padding: 70px 0px 40px;
}

.ryt-room-select-price {
  box-shadow: 0px 4px 15px 0px #0000001A;
  padding: 20px;
  border-radius: 10px;
}

.ryt-room-select-price .top-selct-img {
  height: 175px;
  border-radius: 10px;
}

.hd-spacing-room-selct {
  margin: 20px 0px 10px 0px;
  font-size: 20px;
  line-height: 23.48px;
}

.price-box-room {
  background: #F7E9FF;
  border-radius: 10px;
  padding: 20px;
}

.price-line-dotted {
  display: grid;
  grid-template-columns: 1fr 35%;
  border-bottom: 1px dashed #C1C1C1;
  padding: 9px 0px;
}

/* .price-box-room  .btn-gradient-purple{
  padding: 10px 40px !important;
} */

.price-line-plane {
  border-bottom: 1px solid #C1C1C1;
}

.left-fill-details h4 {
  margin: 22px 0px 12px 0px;
}

.quantity-increase input.quantity-field {
  width: 20%;
  color: #6D338A;
  line-height: 17.61px;
}

.rounded-circle-quantity {
  background: transparent;
  color: #696969;
  padding: 7px 12px 8px 4px;
  line-height: 2px;
  border-radius: 50% !important;
  height: 15px;
  font-size: 13px;
  width: 16px;
  border: 1px solid #696969;
}

.price-quantity-btn {
  grid-template-columns: 40% 1fr;
}

.amnenities-wrapper {
  margin: 50px 0px 50px;
}

/* .policies-wrapper {
  padding: 40px 0px 5px;
} */

/*=====payment details end=====*/


/*=====booking-confirm start====*/
.booking-btns {
  padding: 10px 90px !important;
}

.confirm-price-box {
  background-color: #F5F5F5;
  padding: 30px 40px;
  border: 1px solid #dddd;
}

.booking-btns-wrapper {
  margin: 34px 0px;
}

.booking-confirm-wrapper {
  padding: 30px 0px 70px 0px;
}

/*=====booking-confirm end====*/


/*====hotel-search start====*/
.result-img-rounded img {
  width: 170px;
  height: 170px;
  border-radius: 5px;
  object-fit: cover;
}

.ryt-resultshow-wrapper .star-rating li i {
  font-size: 10px;
  line-height: 15px;
}

.dashed-border-outline {
  border-top: 1px dashed #BABABA;
}

.grid-resultshow {
  box-shadow: 0px 4px 15px 0px #0000001A;
  padding: 10px;
  grid-template-columns: 20% 50% 25%;
  gap: 0px 20px;
  margin-bottom: 30px;
  border-radius: 10px;
}

.srch-result-wrapper h4 {
  padding-bottom: 20px;
}

.srch-price-box {
  margin: 10px 0px 10px 0px;
  border-left: 1px dashed #BABABA;
}

.inner-srch-spacing {
  padding: 0px 10px 0px 20px;
}

.form-check-border {
  border: 1px solid #dddd;
  border-radius: 2px !important;
}

.acoordian-mine-button {
  background: transparent !important;
  font-size: 14px !important;
  font-weight: 600;
  font-family: 'Raleway';
  box-shadow: unset !important;
  margin: 10px 0px 8px 0px !important;
  color: #333 !important;
  margin-top: 0px !important;
}

.left-filter-wrapper .accordion-flush>.accordion-item {
  padding: 15px 0;
}

.acoordian-mine-button::after {
  background-size: unset !important;
  width: 15px !important;
  color: #333;
}

.btn-border-btn-filter {
  margin-bottom: 14px;
  border: 1px solid #DDDD !important;
  color: #696969 !important;
  font-size: 14px !important;
  border-radius: 5px !important;
}

.srch-result-wrapper {
  padding: 70px 0px 40px;
}

.bottom-border-filter {
  border-bottom: 1px solid #dddddddd;
  padding-bottom: 13px;
}

.left-filter-wrapper {
  box-shadow: 0px 4px 15px 0px #0000001A;
  padding: 20px;
  border-radius: 10px;
  border-width: 4px;
  border-style: solid;
  background: linear-gradient(white, white) padding-box, linear-gradient(to top, #ffffff, darkorchid -46%, #ffffff) border-box;
  border-radius: 10px;
  border: 1px solid transparent;
}

/* .ryt-resultshow-wrapper .spacing-result-txt {
  margin: 15px 0px 25px 0px !important;
} */

.room-feature ul li img {
  width: 17px;
}

.room-feature li {
  line-height: 22px !important;
}

.srch-price-box .size-big {
  font-size: 15px;
}

/*====hotel-search end====*/

/*====Tour details start========*/
.box-list-details-wrap {
  border-left: 1px dashed #696969;
  margin-left: 22px;
}

html[dir="rtl"] .box-list-details-wrap {
  border-left: 0px dashed #696969;
  border-right: 1px dashed #696969;
  margin-left: 0px;
  margin-right: 22px;
  padding-left: 0px;
  padding-right: 2rem;
}

.box-list-details-wrap li {
  line-height: 44px;
}

.tour-details-timming {
  background: #F7E9FF;
  border: 1px solid #DDDD;
  border-radius: 10px;
  padding: 30px;
}

.orange-box-price {
  background: #F7BD59;
  padding: 10px 15px;
  border-radius: 5px;
}

.flight-features-list li {
  border-right: 2px solid #9f9a9a;
  padding: 0px 15px;
}

.flight-features-list li:nth-child(1) {
  padding-left: 0px;
}

.flight-features-list li:last-child {
  border: 0px;
}

.border-divider {
  border-top: 1px solid #C1C1C1;
  margin: 10px 0px;
}

.tour-details-timming .quantity-increase input.quantity-field {
  width: 10%;
  background: transparent;
}

.font-25-hd {
  font-size: 25px;
}

/*====Tour details end========*/

/*========tour Payment start=====*/

.border-dotted {
  border: 1px dashed #C1C1C1
}

.border-solid-d {
  border: 1px solid #DDDDDD;
  box-shadow: 0px 4px 15px 0px #0000001A;
  border-radius: 10px;
  padding: 30px;
}

.add-travel-modal .modal-dialog {
  max-width: 555px;
  width: 100%;
}

.travel-detail-header .btn-close,
.policies_box_inner ul li::marker {
  font-size: 10px;
}

.policies_box_inner ul li::marker {
  color: #6D338A;
  font-size: 16px;
}

.add-travel-modal .cross-icon {
  padding: 30px 20px 0px !important;
}

.add-travel-modal .modal-slider-view {
  padding: 25px 20px 30px !important;
}

.d-grid-calender {
  grid-template-columns: 30% 29% 29%;
  gap: 30px;
}

.add-travel-modal .left-fill-details .form-spacing {
  margin-bottom: 20px;
}

.ryt-tour-payment .price-line-dotted {
  grid-template-columns: 1fr 40%;
}

/*========tour Payment end=====*/

/*======flight page start*/
.result-img-rounded .flight-img {
  width: 60px;
  height: 60px;
}

.ryt-filter .grid-resultshow-Flight {
  grid-template-columns: 73% 25%;
}

.flight-time-box {
  display: grid;
  grid-template-columns: 18% 57% 20%;
  gap: 10px;
  padding-top: 12px;
}

.innerdotted-flight::before {
  content: "";
  position: absolute;
  border-top: 1px dashed #BABABA;
  height: 1px;
  width: 100%;
  top: 15px;
}

.innerdotted-flight i {
  color: #BABABA;
  font-size: 12px;
  padding: 10px 0px 0px 15px;
}

html[dir="rtl"] .innerdotted-flight i {
  padding: 0px 0px 2px 15px;
  transform: rotate(180deg);
}

.green-bg-divider {
  color: #31970D;
  background-color: #BEFAAA;
}

.small-box-divider {
  border-radius: 3px;
  padding: 1px 5px;
  position: absolute;
  left: 40%;
  top: 7px;
}

.flight-inner-wrapper {
  display: grid;
  grid-template-columns: 17% 83%;
  padding: 20px 0px 0px 10px;
}

.pink-bg-divider {
  background: #E8B8FF;
  color: #6D338A;
}

.custom-switchers {
  display: flex !important;
  flex-direction: row-reverse;
  padding: 0px !important;
}

html[dir="rtl"] .custom-switchers {
  padding-left: 2.5em !important;
}

html[dir="rtl"] .modal-header .btn-close {
  margin-right: auto;
  margin-left: 0;
}

html[dir="rtl"] .travel-popup .form-select {
  background-position: left .75rem center;
}

/*======flight page end*/

/*======car page start*/
.grid-resultshow-car {
  grid-template-columns: 30% 40% 25%;
  padding: 20px !important;
}

.custom-switchers {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0px;
}

.car-tabs-features {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 22px;
}

.inner-car-tabs {
  border: 1px solid #F5F5F5;
  border-radius: 10px;
  padding: 18px 9px;
  text-align: center;
  margin-bottom: 30px;
}

.inner-car-tabs img {
  opacity: 0.2;
  filter: brightness(0.5);
}

.car-tabs-features .inner-car-tabs.active-tab img {
  opacity: 1;
  filter: brightness(1);
}

.inner-car-tabs.active-tab p {
  color: #6D338A;
}

.left-filter-wrapper .filter-checkobx-form .form-check {
  margin-bottom: 0px;
}

.last-payment .last-txt {
  font-size: 28px;
  font-weight: 700;
}

/*======car page start*/

/* ---06-11-2024-css-start--- */
.top-cities .slick-slide,
.top-hotels-slider-wrap .slick-slide,
.offer-card-wrapper .slick-slide,
.trending-car-wrapper .slick-slide {
  padding: 0 15px !important;
}


.top-hotels-slider-wrap .card.card-hotel-wrapper {
  height: 400px;
  background: transparent;
  cursor: pointer;
}

.trending-car-img img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.trending-car-img {
  height: 160px;
}

.dropdown-mine .currency_txt {
  margin-left: 10px;
}

html[dir="rtl"] .white-border-btn img {
  margin-right: 0px !important;
  margin-left: 10px;
}

.navbar-toggler:focus {
  box-shadow: unset !important;
}

/* ---06-11-2024-css-end--- */

/* ---07-11-2024-css-start--- */
.trending-car-price-wrap,
.trending-car-price-month-wrap {
  word-break: break-all;
}

.currency-translater-dropdown ul.dropdown-menu.show,
.language-translater-dropdown ul.dropdown-menu.show {
  overflow: scroll;
  max-height: 40vh;
  overflow-x: hidden;
  min-height: 100%;
}

.currency-translater-dropdown ul.dropdown-menu.show::-webkit-scrollbar,
.language-translater-dropdown ul.dropdown-menu.show::-webkit-scrollbar {
  display: none;
}

.currency-translater-dropdown ul.dropdown-menu.show {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.currency-translater-header ul.dropdown-menu.show a.dropdown-item.active {
  background-color: #6d338a;
}

html[dir='rtl'] .top-hotels-slider-wrap button.slick-arrow.slick-prev,
html[dir='rtl'] .top-cities button.slick-arrow.slick-prev,
html[dir='rtl'] .offer-card-wrapper button.slick-arrow.slick-prev,
html[dir='rtl'] .trending-car-wrapper button.slick-arrow.slick-prev,
html[dir='rtl'] button.slick-arrow.slick-prev {
  right: auto !important;
  left: 0px !important;
}

html[dir='rtl'] .top-hotels-slider-wrap button.slick-arrow.slick-next,
html[dir='rtl'] .top-cities button.slick-arrow.slick-next,
html[dir='rtl'] .offer-card-wrapper button.slick-arrow.slick-next,
html[dir='rtl'] .trending-car-wrapper button.slick-arrow.slick-next,
html[dir='rtl'] button.slick-arrow.slick-next {
  right: auto !important;
  left: 35px !important;
}

html[dir='rtl'] .dropdown-menu-end {
  right: auto;
  left: 0;
}

/* ---07-11-2024-css-end--- */

/* ---08-11-2024-css-start--- */
.language-translater-dropdown .dropdown-mine img {
  display: none;
}

.footer-menu-link ul li a {
  transform: scale(1);
  position: relative;
  display: inline-block;
  transition: 0.7s all;
  color: #fff;
}

.footer-menu-link ul li a:hover {
  transform: scale(1.2);
  color: #F7BD59;
  transition: 0.7s all;
}

ul.footer-links li a {
  display: inline-block;
  transform: scale(1);
  transition: 0.7s all;
}

html[dir="rtl"] .acoordian-mine-button::after {
  margin: 0px !important;
}

html[dir="rtl"] .srch-price-box {
  border-right: 1px dashed #BABABA;
  border-left: 0px;
}

html[dir="rtl"] .inner-srch-spacing {
  padding: 0px 20px 0px 10px;
}

/* ---08-11-2024-css-end--- */

/* ----11-11-css-start---- */
.trending-car-location {
  font-family: "Raleway", sans-serif;
}

html[dir="rtl"] .rounded-circle-quantity {
  padding: 7px 4px 4px 5px;
  line-height: 2px;
  height: 18px;
  width: 18px;
}

/* ----11-11-css-end---- */

/* ---12-11-2024-css-start--- */
html[dir='rtl'] .hotel-details-modal button.slick-arrow.slick-next {
  left: auto !important;
  right: 0 !important;
}

html[dir='rtl'] .spacing-equal {
  padding: 0px 5px 5px 0px;
}

html[dir='rtl'] .related_rooms_wrapper .room-feature ul li img {
  position: unset;
  margin-right: 0px !important;
}

html[dir='rtl'] .related_rooms_wrapper .room-feature ul li {
  padding-left: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: start;
  gap: 10px;
}

.destination-box .react-datepicker__input-container .react-datepicker__calendar-icon {
  /* top: 50%;
  transform: translateY(-50%);
  left: 5px;
  fill: #696969; */
  display: none;
}

/* .related_rooms_wrapper button.slick-arrow.slick-next::before {
  color: #6D338A;
}

.related_rooms_wrapper button.slick-arrow {
  border-color: #6D338A;
} */


html[dir='rtl'] .price-line-dotted .text-start {
  text-align: right !important;
}

html[dir='rtl'] .price-line-dotted .text-end,
html[dir='rtl'] .price-line-dotted .text-end {
  text-align: left !important;
}

.login-signup-bg-wrapper {
  background-image: url(./assets/login-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  position: relative;
  padding: 50px 0;
}

.login-form-wrap {
  box-shadow: 0px 4px 15px 0px #6D338A26;
  border-radius: 20px;
  background-color: #6D338A;
  padding: 50px 30px 40px 30px;
}

.login-form-wrap ::placeholder,
::placeholder {
  color: #b6b5b5 !important;
}

.pwd-eye-wrap {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  right: 15px;
  background-color: #fff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  color: #ddd;
}

/* ---12-11-2024-css-end--- */


/* ---13-11-2024-css-start--- */
.login-signup-btn-grp {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  gap: 20px;
}

html[dir="rtl"] .login-signup-btn-grp .btn {
  border-radius: 20px 0px 0px 20px;
  border-width: 1px 0px 1px 1px;
}

html[dir="rtl"] .pwd-eye-wrap {
  left: 15px;
  right: auto;
}

html[dir="rtl"] .login-with-otp-wrapper .pwd-eye-wrap {
  left: 15px;
  right: auto;
  transform: translateY(-50%) rotate(180deg);
}

html[dir="rtl"] .login-with-otp-wrapper .signup-phnumber-field-wrap input.form-control,
html[dir="rtl"] .signup-phnumber-field-wrap input.form-control {
  border-left: 1px solid #DDDDDD !important;
  border-right: 0px solid !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

html[dir="rtl"] .login-with-otp-wrapper .signup-phnumber-field-wrap .dropdown-toggle,
html[dir="rtl"] .signup-phnumber-field-wrap .dropdown-toggle {
  border-left: 0px solid !important;
  border-right: 1px solid #DDDDDD !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  padding: 7px 20px 7px 0px !important;
}

.login-signup-btn-grp .btn {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  border-radius: 0px 20px 20px 0px;
  padding: 20px 30px;
  color: #333333;
  font-size: 20px;
  font-family: 'Raleway';
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #FFFFFF;
}

.login-signup-btn-grp .btn:hover {
  background-color: #6D338A;
  color: #fff;
}

.login-signup-btn-grp .btn.active {
  background-color: #6D338A;
  color: #fff;
}

.login-signup-btn-grp .btn img {
  width: 50px;
}

.login-form-wrap .form-check-input[type=checkbox] {
  border: 1px solid #DDDDDD !important;
  background-color: transparent;
}

.pwd-eye-wrap:focus-visible {
  outline: none;
}

.rooms-qty-btn {
  border: 1px solid #783998;
  background: #783998;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: self-end;
  justify-content: center;
  line-height: normal;
}

.disabled-qty-btn {
  border: 1px solid grey;
  background: grey;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: self-end;
  justify-content: center;
  line-height: normal;
}

.login-form-wrap h1 {
  font-size: 40px;
}

.signup-phnumber-field-wrap input.form-control {
  border-left: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.signup-phnumber-field-wrap input.form-control {
  border-left: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.signup-phnumber-field-wrap .dropdown-toggle:hover,
.signup-phnumber-field-wrap .dropdown-toggle:active,
.signup-phnumber-field-wrap .dropdown-toggle:focus-visible {
  background-color: #fff !important;
  color: #333 !important;
  box-shadow: unset !important;
}

.login-enter-otp-input .form-control {
  background-color: #FFFFFF33;
  color: #fff;
}

.login-enter-otp-input .form-control:focus {
  background-color: #FFFFFF33 !important;
  color: #fff !important;
}


.signup-phnumber-field-wrap .dropdown-toggle {
  background-color: #fff !important;
  color: #333 !important;
  border: 1px solid #DDDDDD !important;
  border-radius: 5px !important;
  padding: 7px 0px 7px 20px !important;
  border-right: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  height: 100%;
}

.signup-phnumber-field-wrap .dropdown-menu {
  height: 200px;
  overflow: scroll;
  overflow-x: hidden;
}

/* width */
.signup-phnumber-field-wrap .dropdown-menu::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.signup-phnumber-field-wrap .dropdown-menu::-webkit-scrollbar-track {
  background: #f7f0ff;
}

/* Handle */
.signup-phnumber-field-wrap .dropdown-menu::-webkit-scrollbar-thumb {
  background: #965cb3;
  border-radius: 5px;
}

.login-with-otp-wrapper .signup-phnumber-field-wrap .dropdown-toggle:hover,
.login-with-otp-wrapper .signup-phnumber-field-wrap .dropdown-toggle:active,
.login-with-otp-wrapper .signup-phnumber-field-wrap .dropdown-toggle:focus-visible {
  background-color: #FFFFFF33 !important;
  color: #ddd !important;
  box-shadow: unset !important;
}

.login-with-otp-wrapper .signup-phnumber-field-wrap .dropdown-toggle:hover {
  color: #333 !important;
}

.login-with-otp-wrapper .signup-phnumber-field-wrap input.form-control {
  border-left: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  background-color: #FFFFFF33 !important;
  color: #fff;
}

.login-with-otp-wrapper .signup-phnumber-field-wrap .dropdown-toggle {
  background-color: #FFFFFF33 !important;
  color: #fff !important;
  border: 1px solid #DDDDDD !important;
  border-radius: 5px !important;
  padding: 7px 0px 7px 20px !important;
  border-right: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  height: 100%;
}

.error-msg {
  font-size: 14px;
  margin-top: 6px;
}

/* ---13-11-2024-css-end--- */

/* ---18-11-2024-css-start--- */
.important-note-wrap {
  background-color: #F5F5F5;
  border-radius: 5px;
  padding: 9px 20px;
  margin-bottom: 20px;
}

.important-note-wrap p {
  color: #696969;
  margin-bottom: 0px;
  font-size: 14px;
}

.fight-sort-by-wrapper {
  display: flex;
  justify-content: end;
  width: 25%;
  margin-left: auto;
}

.economy-label-wrapper {
  border: 1px solid #94F274;
  background: #CDFFBC;
  color: #36AB0D;
  padding: 2px 10px;
  font-size: 14px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: max-content;
  margin-left: auto;
}

.grid-resultshow.selected-round-trip {
  background: linear-gradient(#F6E4FF, #F6E4FF) padding-box, linear-gradient(to top left, #ffffff, darkorchid -46%, #ffffff) border-box;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  box-shadow: unset;
}

.departure-heading-wrap,
.return-heading-wrap {
  grid-column: 1 / 3;
  margin-bottom: 5px;
}

.departure-heading-wrap h5,
.return-heading-wrap h5 {
  color: #6D338A;
  font-weight: 600;
  font-family: 'poppins';
}

.price-with-vat-wrap {
  border-bottom: 1px solid #BABABA;
  margin-bottom: 10px;
}

.departure-price-wrap span {
  font-size: 15px;
  display: inline-block;
  color: #333333;
  font-family: 'poppins';
}

.departure-price-wrap span strike {
  color: #808080;
  font-weight: 400;
  font-size: 12px;
}

.departure-price-wrap {
  border-bottom: 2px dotted #BABABA;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.departure-price-wrap h6,
.return-price-not-showing-wrap h6 {
  color: #696969;
  margin-bottom: 5px;
}

.return-price-not-showing-wrap {
  padding-bottom: 10px;
}

.return-price-not-showing-wrap span {
  color: #333333;
  font-weight: 500;
  font-family: 'poppins';
}

.btn-gradient-purple.book-now-disabled {
  background: #DDDDDD !important;
  box-shadow: unset;
  pointer-events: none;
}

.not-selected-return-text {
  background-color: #DDDDDD;
  color: #696969;
}

.disparture-return-tabs {
  border-bottom: 0px !important;
}

.disparture-return-tabs .nav-link {
  padding: 14px 50px;
  border: 0;
  border-bottom: 1px solid #DDDDDD;
}

.disparture-return-tabs .nav-link.active {
  border-bottom: 1px solid #6D338A !important;
  color: #6D338A !important;
  border: 0px;
}

.disparture-return-tabs .nav-link {
  padding: 14px 50px;
  border: 0px !important;
  border-bottom: 1px solid #DDDDDD !important;
  color: #696969;
  font-family: 'poppins';
  font-weight: 400;
}

.custom-radio-btn-wrap {
  grid-column: 1 / 3;
}

.grid-resultshow.selected-container-with-radio {
  border: 1px solid #6D338A;
  background: #F6E4FF;
}

.disparture-return-tabs .nav-link:hover {
  border: 0px;
  color: #6D338A;
}

.flight-booking-details-wrapper .flight-inner-wrapper {
  padding: 0px;
}

.flight-booking-details-wrapper .grid-resultshow {
  padding: 20px;
}

.departure-flight-detail-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.flight-class-select-wrap .form-select {
  background-color: #CDFFBC;
  border: 1px solid #94F274;
  color: #36AB0D;
  font-size: 14px;
}

.baggage-details-container-wrapper {
  border: 1px solid #DDDDDD;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  margin-top: 10px;
}

.baggage-details-container-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid #C1C1C1;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.add-cancellation-policy-wrap input {
  border: 1px solid #6D338A;
}

.baggage-note-wrap {
  background-color: #F5F5F5;
  padding: 9px 20px;
  border-radius: 5px;
  margin-top: 16px;
}

.baggage-note-wrap p {
  margin-bottom: 0px;
}

.additional-weight-price h5 {
  font-family: 'poppins';
}

.add-baggage-list-wrap {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 10px;
  border-bottom: 1px solid #C1C1C1;
  padding-bottom: 25px;
  margin-bottom: 25px;
  align-items: center;
}

/* ---18-11-2024-css-end--- */

/* ----20-11-2024-css-start---- */
.car-pickup-details-wrap {
  box-shadow: 0px 4px 15px 0px #6D338A26;
  border-radius: 10px;
  padding: 30px 20px;
  margin-bottom: 30px;
}

html[dir="rtl"] .flight-features-list li:nth-child(1) {
  padding-left: 15px;
  padding-right: 15px;
}

/* ----20-11-2024-css-end---- */

/* ----26-11-2024-css-start---- */
/* width */
.search-location-options::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.search-location-options::-webkit-scrollbar-track {
  background: #f7f0ff;
  border-radius: 8px;
}

/* Handle */
.search-location-options::-webkit-scrollbar-thumb {
  background: #965cb3;
  border-radius: 5px;
}

.search-location-options li:hover {
  background-color: #f7f0ff !important;
}

.destination-box .react-datepicker__input-container button.react-datepicker__close-icon::after {
  background-color: #6D338A;
}

.detail-first-img-wrap img,
.detail-right-side-col-wrap .spacing-equal img,
.detail-left-bottom-img-wrap img {
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.detail-first-img-wrap {
  height: 275px;
}

.detail-right-side-col-wrap .spacing-equal,
.detail-right-side-col-wrap .spacing-equal .slider-image-popup {
  height: 240px;
}

.detail-left-bottom-img-wrap {
  height: 200px;
}

.second-last-img-wrap .spacing-equal,
.last-img-wrap .spacing-equal {
  padding-bottom: 0;
}

.top-related_rooms_wrapper .realted_inner .room-feature ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}

.booking-tab-form .details_bg_lyt .destination-box .form-control {
  /* border: 0px !important; */
  padding-top: 29px !important;
  padding-bottom: 29px !important;
}

html[dir="rtl"] .ryt-room-select-price .arrow-right-icon-wrap {
  display: inline-block;
  transform: rotate(180deg) !important;
}

/* ----26-11-2024-css-end---- */

/* ---03-12-2024-css-start--- */
.select-nationality-popup-wrap .dropdown-options.select-nationality-option {
  position: unset !important;
  margin-top: 5px !important;
  border-radius: 10px;
}

.top-hotels-slider-wrap .card.card-hotel-wrapper .card-body.card-content h6 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.dropdown-mine:active {
  outline: none;
  border: 0;
}

ul.dropdown-options.search-location-options li {
  display: flex;
  gap: 15px;
  border-bottom: 1px solid #ddd;
}

/* ---03-12-2024-css-end--- */




/* =========== Admin-css-start-here ============= */
/* .admin-left-sidebar-wrap {
  background-color: #632781;
  color: #fff;
  padding-top: 40px;
  min-width: 205px;
} */

.admin-booking-cashback-wrap .form-check-input {
  height: 1.5em;
  width: 2.5em !important;
  border: 1px solid #6D338A;
}

.admin_pannel .modal-dialog {
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

.admin_pannel .btn-gradientlg-purple,
.admin_pannel .ylw-theme-button {
  padding: 10px 20px !important;
  font-size: 15px;
}

.desktop_navbar_wrap .admin-sidebar-toggle-wrap.for-mobile {
  display: none;
}

.admin-sidebar-toggle-wrap .navbar-brand {
  width: 60%;
  display: inline-block;
}

.admin-sticky-header-wrap {
  position: sticky;
  top: 0;
  z-index: 999;
}

.admin_pannel .admin-sidebar-toggle-wrap {
  display: flex;
  align-items: center;
  gap: 16px;
  position: absolute;
  top: 10px;
  left: -20px;
  z-index: 999;
  transition: 0.5s;
}

.admin_pannel .admin-sidebar-toggle-wrap .sidebar-toggler {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #6d338a;
  border-radius: 40px;
  border: 4px solid #f6e4ff;
  color: #fff;
}

.admin_pannel .admin-right-sidebar-wrapper.open .admin-sidebar-toggle-wrap {
  left: 0;
  transition: 0.5s;
}

.admin_pannel .admin-right-sidebar-wrapper.open .admin-sidebar-toggle-wrap .sidebar-toggler .cross {
  display: block;
  padding-top: 2px;
}

.admin_pannel .admin-right-sidebar-wrapper .admin-sidebar-toggle-wrap .sidebar-toggler .hem {
  padding-top: 2px;
}

.admin_pannel .admin-right-sidebar-wrapper.open .admin-sidebar-toggle-wrap .sidebar-toggler .hem {
  display: none;
}

.admin_pannel .admin-sidebar-toggle-wrap .sidebar-toggler .cross {
  display: none;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 280px;
  height: 100vh;
  /* overflow-y: auto; */
  transition: 0.5s;
  z-index: 999;
}

.content {
  margin-left: 280px;
  min-height: 100vh;
  transition: 0.5s;
}

.admin_pannel .header {
  margin-left: 280px;
  transition: 0.5s;
}

.admin-left-sidebar-wrap .ps-sidebar-container {
  background-color: transparent;
}

.admin-left-sidebar-wrap .ps-sidebar-root {
  border: 0;
  min-width: 100%;
  max-width: 100%;
}

.light-border-circle-wrapper {
  background-image: url(./assets/admin-profile-light-circle.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 160px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.admin-profile-img-wrap {
  background-image: url(./assets/semi-light-circle-img.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 128px;
  width: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.admin-profile-img-wrap img {
  height: 110px;
  width: 110px;
  border-radius: 50%;
  object-fit: cover;
}

.edit-profile-popup-wrap .admin-profile-img-wrap img {
  width: 100%;
  height: 100%;
}

.admin-profile-detail-wrap {
  padding: 20px 20px 20px 30px;
}

.custom-admin-nav-tabs-wrap {
  background-color: #6D338A;
  padding: 30px 0;
}

.custom-admin-nav-tabs-wrap li.ps-menuitem-root a {
  width: 95%;
  margin-left: auto;
  position: relative;
}

.custom-admin-nav-tabs-wrap li.ps-menuitem-root a::before {
  content: "";
  background: linear-gradient(90deg, rgba(126, 71, 154, 0) 0%, #7E479A 50%, rgba(126, 71, 154, 0) 100%);
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.custom-admin-nav-tabs-wrap .nav-item .nav-link:hover {
  background-color: #F6E4FF;
  border-radius: 10px;
  color: #6D338A !important;
}

.custom-admin-nav-tabs-wrap .nav-item .nav-link:hover span {
  color: #6D338A !important;
}

.custom-admin-nav-tabs-wrap .nav-item .nav-link.active span {
  color: #6D338A !important;
}

.custom-admin-nav-tabs-wrap .nav-item .nav-link.active {
  background-color: #F6E4FF;
  border-radius: 10px;
  color: #6D338A !important;
  font-weight: 500;
  font-family: 'poppins';
}

.custom-admin-nav-tabs-wrap .nav-item .nav-link {
  border-radius: 10px;
}

.custom-admin-nav-tabs-wrap li.ps-menuitem-root a span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom-admin-nav-tabs-wrap li.ps-menuitem-root a span svg {
  height: 20px;
  width: 20px;
}

.admin-right-sidebar-wrapper {
  padding-top: 63px;
  padding-bottom: 80px;
  padding-left: 20px;
  padding-right: 20px;
}

.admin-booking-detail-containers {
  display: grid;
  gap: 20px;
  margin-bottom: 40px;
  flex-wrap: wrap;
  row-gap: 0;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* justify-content: space-between; */
}

.total-booking-container-wrap {
  background: linear-gradient(180deg, #6D338A 0%, #1C0D24 100%);
}

.processing-booking-container-wrap {
  background: linear-gradient(180deg, #F7BD59 0%, #916F34 100%);
}

.completed-booking-container-wrap {
  background: linear-gradient(180deg, #41DAC0 0%, #237466 100%);
}

.cancel-booking-container-wrap {
  background: linear-gradient(180deg, #AA0000 0%, #440000 100%);
}

.total-booking-container-wrap h1,
.cancel-booking-container-wrap h1,
.completed-booking-container-wrap h1,
.processing-booking-container-wrap h1,
.total-booking-container-wrap p,
.cancel-booking-container-wrap p,
.completed-booking-container-wrap p,
.processing-booking-container-wrap p {
  font-family: 'poppins';
  z-index: 1;
  position: relative;
}

.total-booking-container-wrap h1,
.cancel-booking-container-wrap h1,
.completed-booking-container-wrap h1,
.processing-booking-container-wrap h1 {
  margin-bottom: 0px;
}

.total-booking-container-wrap::before,
.cancel-booking-container-wrap::before,
.completed-booking-container-wrap::before,
.processing-booking-container-wrap::before {
  content: "";
  background-image: url(./assets/dashboard-col-bg-lines.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.total-booking-container-wrap,
.cancel-booking-container-wrap,
.completed-booking-container-wrap,
.processing-booking-container-wrap {
  border-radius: 20px;
  border-left: 2px solid #fff;
  padding: 25px 30px;
  position: relative;
  width: 100%;
}

.upcoming-list-table-wrapper thead tr th {
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 500;
  line-height: 22.5px;
  padding-top: 15px;
  padding-bottom: 15px;
  vertical-align: middle;
}

.sticky-action-td {
  position: sticky;
  right: 0;
  background-color: #fff !important;
}

.sticky-action-td:before {
  content: "";
  height: 100%;
  width: 0px;
  position: absolute;
  top: 0;
  left: 0;
  border-left: 1px solid #f4f4f4;
}

.upcoming-list-table-wrapper tbody tr td,
.upcoming-list-table-wrapper tbody tr th {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #696969;
  padding-top: 15px;
  padding-bottom: 15px;
  vertical-align: middle;
}

.action-icon-wrap .eye-icon-wrap {
  font-size: 16px;
  color: #6D338A;
}

.action-icon-wrap .download-icon-wrap {
  color: #36AB0D;
  /* font-size: 16px;
  margin-left: 8px; */
}

.action-icon-wrap .form-check.form-switch {
  margin-bottom: 0;
}

.action-icon-wrap .cross-icon-wrap {
  font-size: 22px;
  color: #FF0000;
}

.status-label .processing-label {
  display: inline-block;
  border: 1px solid #F7BD59;
  padding: 3px 8px;
  background-color: #FFEBCA;
  color: #F7BD59;
  border-radius: 5px;
}

.status-label .confirmed-label {
  border: 1px solid #45BEF1;
  display: inline-block;
  padding: 3px 8px;
  background-color: #E8F8FF;
  color: #45BEF1;
  border-radius: 5px;
}

.status-label .cancel-label {
  background-color: #FFDFDF;
  border: 1px solid red;
  border-radius: 5px;
  color: red;
  display: inline-block;
  padding: 3px 8px;
}

.status-label .completed-label {
  background-color: #CDFFBC;
  border: 1px solid #36AB0D;
  border-radius: 5px;
  color: #36AB0D;
  display: inline-block;
  padding: 3px 8px;
}

.table-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #6D338A;
  padding: 23px 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.table-col-name-td {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100px;
  white-space: nowrap;
}

.add-review-disable {
  color: #6D338A;
  opacity: 0.5;
}

.table-amount-col-data {
  color: #F7BD59;
  font-weight: 500;
}

.upcoming-list-table-wrapper {
  background-color: #fff;
  border-bottom-left-radius: 10px;
  padding-bottom: 10px;
  border-bottom-right-radius: 10px;
  display: inline-block;
  white-space: nowrap;
  overflow: scroll;
  overflow-y: hidden;
  width: 100%;
}

.box-with-shadow-bg-wrap {
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 4px 15px 0px #6D338A26;
}

.custom-admin-nav-tabs-wrap .ps-submenu-content li.ps-menuitem-root.not-active a {
  color: #6D338A;
}

.select-lang-setting-admin {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 16px;
  font-size: 15px;
  column-gap: 16px;
}


.admin-setting-dropdown-leftside .dropdown-menu {
  position: unset !important;
  transform: unset !important;
  inset: unset !important;
  background-color: #f6e4ff;
  border-radius: 10px;
  margin-top: 7px;
  width: 90%;
  margin-left: auto;
  /* padding: 0 10px; */
}

.admin-setting-dropdown-leftside .dropdown-menu .dropdown-item:hover {
  background-color: #f4f4f4;
}

.admin-setting-dropdown-leftside .dropdown-menu .dropdown-item {
  padding: 0.5rem 1rem;
  color: #6D338A !important;
  font-weight: 500;
  font-size: 16px;
  background: transparent;
}

.admin-setting-dropdown-leftside .nav-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}


.admin-setting-dropdown-leftside .nav-link:focus,
.admin-setting-dropdown-leftside .nav-link:hover {
  color: #fff;
}

.modal-content {
  border: 0px !important;
}

.modal-content .btn-close {
  position: absolute;
  top: -10px;
  right: -10px;
}

.custom-admin-nav-tabs-wrap .nav-item {
  width: 100%;
}

.admin-login-bg-wrap .login-form-wrap {
  padding-top: 30px;
}

/* -----09-01-2025-css-start---- */
.destination-box .ant-picker.ant-picker-range span.ant-picker-suffix {
  display: none;
}

/* .ant-picker-dropdown .ant-picker-prev-icon::before,
.ant-picker-dropdown .ant-picker-next-icon::before,
.ant-picker-dropdown .ant-picker-super-prev-icon::before,
.ant-picker-dropdown .ant-picker-super-next-icon::before,
.ant-picker-dropdown .ant-picker-super-prev-icon::after,
.ant-picker-dropdown .ant-picker-super-next-icon::after {
  border-color: #783998;
} */

.ant-picker-dropdown .ant-picker-header>button:hover {
  color: #783998;
}

div#simple-popover {
  width: 20rem;
}

.booking-form-grid .destination-box .ant-picker.ant-picker-range {
  width: 100%;
}

.booking-form-grid .destination-box .ant-picker.ant-picker-range:hover,
.booking-form-grid .destination-box .ant-picker.ant-picker-range:focus,
.booking-form-grid .destination-box .ant-picker.ant-picker-range:focus-within,
.booking-tab-form .destination-box:hover input.form-control,
.ant-picker-outlined:hover {
  border-color: #783998 !important;
}

.ant-picker-range .ant-picker-active-bar {
  background: #783998 !important;
}

.show_results-filter-wrap {
  width: 22%;
}

.details_bg_lyt img.calendar-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.ant-picker .ant-picker-suffix {
  display: none;
}

.room-feature ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.srch-details-box .srch-details-rooms-location p {
  font-size: 12px;
  font-weight: 400;
}

/* -----09-01-2025-css-end---- */

/* -----16-01-2025-css-start---- */
span.anticon.anticon-close-circle {
  color: #6D338A;
}

.support-img-wrap {
  width: 35%;
}

.total-data-img-wrap {
  width: 20%;
}

button.MuiButtonBase-root.MuiPaginationItem-root {
  border-radius: 3px;
}

.details-room-srch.booking-tab-form .details_bg_lyt .destination-box .ant-picker.ant-picker-range {
  padding-top: 29px !important;
  padding-bottom: 29px !important;
}

.details-room-srch.booking-tab-form .details_bg_lyt .destination-box .ant-picker.ant-picker-range .ant-picker-input input {
  padding: 0;
}

.cashback-walletw-wrap {
  font-size: 18px;
  border: 1px solid #6D338A;
  border-radius: 5px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #6D338A;
  font-weight: 500;
  line-height: normal;
}

/* -----16-01-2025-css-end---- */



@media (min-width: 992px) {
  .sidebar {
    margin-left: 0;
  }

  .sidebar.open {
    margin-left: -280px;
  }

  .content,
  .admin_pannel .header {
    width: calc(100% - 280px);
  }

  .content.open,
  .admin_pannel .header.open {
    width: 100%;
    margin-left: 0;
  }
}


@media (min-width: 200px) {

  .animate,
  .admin-setting-dropdown-leftside .dropdown-menu {
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0rem);
    opacity: 1;
  }

  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }

  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn,
.admin-setting-dropdown-leftside .dropdown-menu {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

.admin_pannel .custom-admin-nav-tabs-wrap {
  min-height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
}

/* width */
.admin_pannel .custom-admin-nav-tabs-wrap::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
.admin_pannel .custom-admin-nav-tabs-wrap::-webkit-scrollbar-track {
  background: #f4f4f4;
}

/* Handle */
.admin_pannel .custom-admin-nav-tabs-wrap::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 5px;
}

.custom-popup-scrollbar {
  height: 30rem;
  overflow: scroll;
  overflow-x: hidden;
  padding-right: 10px;
}

/* width */
.custom-popup-scrollbar::-webkit-scrollbar {
  width: 8px;
  border-radius: 5px;
}

/* Track */
.custom-popup-scrollbar::-webkit-scrollbar-track {
  background: #f7f0ff;
  border-radius: 8px;
}

/* Handle */
.custom-popup-scrollbar::-webkit-scrollbar-thumb {
  background: #965cb3;
  border-radius: 5px;
}

.header-profile-dropdown {
  border: 0px !important;
}

.superadmin-dashboard .sidebar.custom-admin-nav-tabs-wrap .nav-item a.nav-link>span {
  width: 25px;
}

.superadmin-dashboard .custom-admin-nav-tabs-wrap .nav-item:nth-child(6) a span svg {
  height: 24px;
  width: 24px;
}

.superadmin-dashboard .custom-admin-nav-tabs-wrap .nav-item:nth-child(6) a span,
.superadmin-dashboard .custom-admin-nav-tabs-wrap .nav-item:last-child a span,
.superadmin-dashboard .custom-admin-nav-tabs-wrap .nav-item:nth-child(3) a span {
  margin-top: 0px !important;
}

.superadmin-dashboard .custom-admin-nav-tabs-wrap .nav-item:last-child a span svg,
.superadmin-dashboard .custom-admin-nav-tabs-wrap .nav-item:nth-child(3) a span svg {
  height: 22px;
  width: 22px;
}

/* ----21-01-2025-css-start---- */
.reviews-box .star-rating {
  gap: 2.5px;
  display: flex !important;
}

.reviews-box {
  gap: 4px;
}

.hotel-details-banner .related_rooms_wrapper .realted_inner {
  margin-bottom: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.hotel-details-banner .related_rooms_wrapper .realted_inner .inner-details-cnt {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.hotel-details-banner .related_rooms_wrapper .realted_inner .inner-details-cnt .price-room {
  margin-top: auto;
}

div#similarpr-tab .slick-slider .slick-list {
  padding-bottom: 20px;
}

.hotel-details-banner div#reviews-tab {
  margin-top: 0;
}

ul.dropdown-options.search-location-options li h6 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 3px;
  background-color: transparent !important;
}

.country-city-name {
  background-color: transparent !important;
}

ul.dropdown-options.search-location-options li p {
  font-size: 12px;
  color: #333;
  font-weight: 400;
  background-color: transparent !important;
}

.total-rating-container {
  background: linear-gradient(180deg, #8740AB 0%, #49225C 100%) !important;
  border-radius: 5px;
  padding: 5px 6px;
  color: #fff;
  box-shadow: 0px -0.3px 0px 1px #582970;
  display: flex;
  align-items: center;
  gap: 5px;
}

.total-rating-container i {
  font-size: 8px;
  margin-top: -1px;
}

.google-hotel-rating {
  display: inline-block;
  position: relative;
  top: -3px;
  margin-left: 5px;
  transform: translateY(-3px);
}

.srch-details-box .google-hotel-rating {
  top: 0px;
  transform: translateY(-3px);
}

.google-hotel-rating .star-rating i {
  color: #6D338A !important;
}

.related_rooms_wrapper.top-related_rooms_wrapper .slick-slider {
  display: flex;
}

.related_rooms_wrapper.top-related_rooms_wrapper .slick-slider .slick-list {
  padding-bottom: 20px;
}

/* ----21-01-2025-css-end---- */

/* ----24-01-2025-css-start----- */
.ant-picker-dropdown .ant-picker-cell-inner::before {
  border: 1px solid #6D338A !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
  background-color: #6D338A;
  border-radius: 4px;
}

.ant-picker-dropdown .ant-picker-header-view>button:hover {
  color: #6D338A;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled):before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled):before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range:not(.ant-picker-cell-disabled):before {
  background-color: #f7f0ff;
}

.ant-picker .ant-picker-separator {
  width: 8px;
  height: 8px;
  font-size: 0;
  border: solid rgba(0, 0, 0, 0.25);
  border-width: 0 2px 2px 0;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-right: 12px;
  margin-top: 3px;
}

/* ----24-01-2025-css-end----- */

/* -----30-01-2025-css-start----- */
.signup-phnumber-field-wrap .dropdown .dropdown-menu input:focus-visible {
  border: 0;
  outline: none;
}

.signup-phnumber-field-wrap .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #f7f0ff;
  color: #212529;
}

.persons-select-popup.popover-body .persons-qty-row:last-child {
  border-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.left-fill-details .signup-phnumber-field-wrap .dropdown-menu input {
  padding-left: 48px !important;
}

.total-data-container-wrapper {
  box-shadow: 0px 4px 15px 0px #6D338A26;
  background-color: #6D338A;
  border-radius: 10px;
  padding: 35px 25px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}

.copy-link-icon-wrap {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.share-link-container-wrapper {
  box-shadow: 0px 4px 15px 0px #6D338A26;
  border-radius: 10px;
  background-color: #fff;
  padding: 30px;
}

.share-social-icon-wrapper {
  box-shadow: 0px 4px 15px 0px #6D338A26;
  background-color: #6D338A;
  border-radius: 5px;
  padding: 10px;
}

.share-with-your-friend-wrap {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
}

.total-data-wrap h2,
.total-data-wrap h6 {
  font-family: "Poppins", sans-serif !important;
}

/* -----30-01-2025-css-end----- */



/* =========== Admin-css-end-here ============= */

/* ======= User-Dashboard-css-start-here ========= */
.user-dashboard .user-dashboard-left-sidebar {
  background-color: #632781;
  margin: 0 -10px;
  padding-top: 30px;
  width: -webkit-fill-available;
  margin-bottom: 0px;
}

.user-dashboard-left-sidebar .navbar-brand {
  width: 50%;
  text-align: center;
  margin: 0 auto;
}

.user-dashboard-left-sidebar {
  width: -webkit-fill-available;
  margin: 0 -10px;
  margin-bottom: 25px;
}

.user-dashboard .user-dashboard-left-sidebar .admin-profile-detail-wrap {
  padding-top: 0;
}

.user-dashboard .custom-admin-nav-tabs-wrap {
  padding-top: 0;
}

.user-dashboard .custom-admin-nav-tabs-wrap>div {
  padding-top: 0px !important;
}

.user-dashboard .custom-admin-nav-tabs-wrap .nav-item .nav-link span svg {
  height: 18px;
  width: 18px;
}

.user-dashboard .custom-admin-nav-tabs-wrap .nav-item:last-child .nav-item a.nav-link span svg {
  height: 22px;
  width: 22px;
}

.dashboard-footer-copyright {
  position: absolute;
  background: transparent;
  bottom: 0;
  z-index: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 20px;
}

.booking-detail-header-btns .add-review-btn.btn-gradient-purple {
  background: transparent !important;
  color: #6d338a !important;
}

.booking-detail-header-btns .add-review-btn.btn-gradient-purple:hover {
  background: linear-gradient(180deg, #8740AB 0%, #49225C 100%) !important;
  color: #fff !important;
}

.booking-detail-status-label {
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 5px;
}

.booking-detail-status-label.completed {
  border: 1px solid #36AB0D;
  background-color: #CDFFBC;
  color: #36AB0D;
}

.booking-detail-status-label.confirmed {
  border: 1px solid #45BEF1;
  background-color: #E8F8FF;
  color: #45BEF1;
}

.booking-detail-status-label.cancel {
  border: 1px solid red;
  background-color: #FFDFDF;
  color: red;
}

.booking-detail-status-label.processing {
  border: 1px solid #F7BD59;
  background-color: #FFEBCA;
  color: #F7BD59;
}

.booking-detail-header-btns .download-btn.btn-gradient-purple {
  background: #36AB0D !important;
  box-shadow: 0px 1px 10px 0px #58297026;
  border: 1px solid #36AB0D;
}

.booking-detail-header-btns .download-btn.btn-gradient-purple:hover {
  background: #fff !important;
  color: #36AB0D !important;
}

.dashboard-booking-details-row .ryt-room-select-price {
  background-color: #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 0;
}

.dashboard-booking-details-row .ryt-room-select-price .price-line-dotted {
  align-items: center;
}

.dashboard-left-panel-profile-wrap img {
  border-radius: 50%;
  object-fit: cover;
}

.dashboard-left-panel-profile-wrap {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  border: 2px solid #6D338A;
}

.admin-user-personal-detail-wrap .admin-profile-wrap {
  display: flex;
  gap: 8px;
  align-items: center;
}

.admin-user-personal-detail-wrap .admin-profile-wrap .admin-profile-name {
  display: flex;
  align-items: center;
  gap: 16px;
}

.user-dashboard .user-dashboard-left-sidebar .admin-profile-detail-wrap .language-translater-dropdown button#Currencydropdown {
  padding-left: 0;
}

.user-dashboard .user-dashboard-left-sidebar .admin-profile-detail-wrap .language-translater-dropdown button#Currencydropdown span {
  margin-left: 0;
}

/* ======= User-Dashboard-css-start-here ========= */




/*=====media Query=======*/
@media (max-width:4000px) {
  /* .bottom_banner_img img {
    height: 210px;
  } */
}

@media (max-width:1399px) {
  /* .bottom_banner_img img {
    height: 182px;
  } */
}

@media (max-width:1199px) {
  /* .bottom_banner_img img {
    height: 152px;
  } */

  .related_rooms_wrapper .room-feature ul li {
    line-height: 13px !important;
    margin-bottom: 10px;
  }
}

@media (max-width:1024px) {
  .related_rooms_wrapper .price-room p b {
    font-size: 25px;
  }

  .tab-btm-spacing {
    margin-bottom: 0px;
    margin-right: 10px !important;
  }

  .realted_inner .price-room {
    display: block !important;
    margin-bottom: 20px;
  }

  .price-box-bottom-txt p {
    margin-bottom: 0px;
  }

  .tab-0-content-sp {
    margin-bottom: 0px;
  }

  .p-large {
    font-size: 16px;
  }

  .box-list-details-wrap li {
    line-height: 35px;
  }

  .timming-txt-wrap {
    margin-bottom: 10px;
  }

  .quantity-increase {
    margin-left: 10px;
  }

  .grid-wrapper-form {
    grid-template-columns: 18% 18% 1fr 24%;
  }

  .hotel-details-modal .modal-dialog {
    max-width: 860px;
  }

  .ryt-filter .grid-resultshow-Flight {
    grid-template-columns: 64% 30%;
  }

  .flight-time-box {
    grid-template-columns: 24% 46% 24%;
  }

  .dotted-flight-box .flight-bottom-txt {
    padding: 0px !important;
  }

  .card-hotel-wrapper .reviews-box {
    gap: 10px !important;
    justify-content: center !important;
    align-items: baseline !important;
  }

  .inner-newslwtter-wrapper {
    padding: 70px 150px;
  }

  .hotel-details-wrapper .nav-tabs li.nav-item .nav-link {
    padding: 20px 35px;
  }

  /* .bottom_banner_img img {
    height: 150px;
  } */

  .detail-left-bottom-img-wrap {
    height: 150px;
  }

  .detail-right-side-col-wrap .spacing-equal,
  .detail-right-side-col-wrap .spacing-equal .slider-image-popup {
    height: 215px;
  }

  .grid-resultshow {
    grid-template-columns: 20% 46% 28%;
  }

  .admin-booking-detail-containers {
    gap: 16px;
  }

  .booking-detail-header.mb-5 {
    margin-bottom: 0px !important;
    flex-wrap: wrap;
    gap: 25px;
  }

  .booking-detail-header-btns {
    margin-bottom: 16px;
    margin-left: auto;
  }
}

@media (max-width:992px) {
  .mobile-translator {
    display: inline-flex !important;
  }

  .navbar-toggler {
    background: #fff;
  }

  .header-menus-wrap .desktop-translator {
    display: none !important;
  }

  .header-menus-wrap .navbar-header {
    gap: 16px;
  }

  .header .navbar-brand img {
    width: 105px;
  }

  .inner-newslwtter-wrapper h3 {
    font-size: 20px;
  }

  .inner-newslwtter-wrapper {
    padding: 30px 250px !important;
  }

  .inner-newslwtter-wrapper p {
    font-size: 13px;
  }

  .inner-newslwtter-wrapper::before,
  .inner-newslwtter-wrapper::after {
    display: none;
  }

  .footer-grid-wrap {
    grid-template-columns: 50% 34% 1fr;
    gap: 25px;
  }

  .grid-wrapper-form {
    grid-template-columns: 18% 18% 1fr 22%;
  }

  .section-padding {
    padding: 40px 0px;
  }

  .booking-tour-grid {
    grid-template-columns: 1fr 20% 18% !important;
  }

  .booking-form-grid.flight-oneway-grid {
    grid-template-columns: 1fr 1fr !important;
  }

  .booking-tab-form .destination-box img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3%;
  }

  html[dir="rtl"] .booking-tab-form .destination-box img {
    right: 3%;
  }

  .footer {
    padding: 80px 0px 410px 0px;
  }

  .grid-wrapper-form {
    grid-template-columns: 18% 18% 1fr 27%;
  }

  .booking-form-grid {
    width: 90%;
  }

  .slick-slide {
    padding-right: 0px !important;
  }

  /* .slick-track {
    display: flex !important;
    gap: 20px;
  } */

  .top-hotels-slider-wrap h2,
  .top-cities-slider-wrapper h2,
  .trending-car-wrapper h2,
  .offer-card-wrapper h2 {
    margin-bottom: 10px !important;
  }

  html[dir='rtl'] .top-hotels-slider-wrap button.slick-arrow.slick-next,
  html[dir='rtl'] .top-cities button.slick-arrow.slick-next,
  html[dir='rtl'] .offer-card-wrapper button.slick-arrow.slick-next,
  html[dir='rtl'] .trending-car-wrapper button.slick-arrow.slick-next {
    right: auto !important;
    left: 50px !important;
  }

  html[dir='rtl'] .top-hotels-slider-wrap button.slick-arrow.slick-prev,
  html[dir='rtl'] .top-cities button.slick-arrow.slick-prev,
  html[dir='rtl'] .offer-card-wrapper button.slick-arrow.slick-prev,
  html[dir='rtl'] .trending-car-wrapper button.slick-arrow.slick-prev {
    right: auto !important;
    left: 15px !important;
  }

  .ryt-filter .grid-resultshow-Flight {
    grid-template-columns: 1fr;
    column-gap: 0px;
  }

  .srch-price-box {
    border-left: 0;
  }

  .inner-srch-spacing {
    padding: 0px 10px 0px 10px;
  }

  .roundedFlights-filters .inner-srch-spacing {
    width: 100%;
  }

  .roundedFlights-filters .srch-price-box {
    grid-column: 1 / 3;
  }

  .departure-flight-detail-heading.d-flex {
    display: grid !important;
    gap: 0px !important;
  }

  .flight-booking-details-wrapper .flight-inner-wrapper {
    grid-template-columns: 1fr;
  }

  .flight-booking-details-wrapper .flight-time-box {
    grid-template-columns: 1fr;
  }

  .flight-booking-details-wrapper .dotted-flight-box {
    order: 3;
    grid-column: 1 / 3;
    margin-bottom: 20px;
  }

  .baggage-details-container-grid {
    grid-template-columns: 1fr;
  }

  .admin_pannel .admin-right-sidebar-wrapper.open .admin-sidebar-toggle-wrap {
    left: 260px;
  }

  .admin_pannel .admin-sidebar-toggle-wrap {
    left: 0px;
  }
}

@media (max-width: 991.98px) {
  .sidebar {
    margin-left: -280px;
  }

  .sidebar.open {
    margin-left: 0;
  }

  .content,
  .admin_pannel .header {
    width: 100%;
    margin-left: 0;
  }

  .desktop_navbar_wrap .admin-sidebar-toggle-wrap.for-mobile {
    display: block;
  }

  .admin-sticky-header-wrap .admin-sidebar-toggle-wrap.for-desktop .sidebar-toggler {
    display: none;
  }

  .admin-sticky-header-wrap .admin-sidebar-toggle-wrap.for-desktop .navbar-brand {
    width: 100%;
  }

  .desktop_navbar_wrap .admin-sidebar-toggle-wrap.for-mobile .cross,
  .header.open .desktop_navbar_wrap .admin-sidebar-toggle-wrap.for-mobile .hem {
    display: none;
  }

  .header.open .desktop_navbar_wrap .admin-sidebar-toggle-wrap.for-mobile .cross {
    display: block;
  }
}

@media (max-width:991px) {
  .inner-newslwtter-wrapper {
    padding: 30px 130px !important;
  }

  .header .navbar .navbar-toggler {
    background: #fff;
  }

  .mobile-login-signup .user_btn {
    border: 1px solid #fff;
    border-radius: 8px;
  }

  .mobile-login-signup .user_btn::after {
    display: none;
  }

  .mobile-login-signup ul li a {
    font-size: 14px;
  }

  /* .top-cities .card-hotel-wrapper,
  .trending-box-content {
    margin-bottom: 25px;
  } */

  .where-to-travel-wrappee {
    padding: 15px 0;
  }

  .border-box-travel {
    margin-bottom: 15px;
  }

  .section-padding {
    padding: 30px 0px;
  }

  .offer-card-box img {
    height: 160px;
    width: 100%;
  }

  .offer-card-box .card {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .offer-card-box .card-body {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mobile-login-signup {
    display: block !important;
  }

  .header-menus-wrap {
    position: absolute;
    background: #4f1e65;
    left: 0;
    right: 0;
    top: 0px;
    height: 100vh;
    z-index: 1;
    padding: 26px;
    width: 50%;
  }

  .header-menus-wrap .navbar-nav {
    align-items: start !important;
  }

  .header .mobile-translator {
    position: relative;
    z-index: 1;
  }

  .inner-banner-wrapper .banner_time img {
    width: 120px;
  }

  .grid-wrapper-form {
    grid-template-columns: 18% 18% 1fr 30%;
  }

  .hotel-details-wrapper .nav-tabs li.nav-item .nav-link {
    padding: 20px 16px !important;
  }

  .hotel-details-banner .bottom_banner_img img {
    height: 109px;
  }

  .hotel-details-wrapper .page-id-tabs {
    padding: 20px 28px;
  }

  .select-room_grid_row.row {
    gap: 13px 0px;
  }

  .related_rooms_wrapper .room-feature ul {
    margin-bottom: 0px;
  }

  .width-mobile-adjust {
    width: 50% !important;
  }

  .tour-details-timming {
    margin-top: 30px;
  }

  .booking-tour-grid {
    grid-template-columns: 1fr 20% 26% !important;
  }

  .grid-resultshow-car {
    grid-template-columns: 20% 42% 32%;
  }

  .ryt-filter {
    margin-top: 30px;
  }

  .grid-resultshow-Flight {
    grid-template-columns: 67% 30%;
  }

  .grid-resultshow {
    grid-template-columns: 20% 46% 28%;
  }

  .ryt-resultshow-wrapper .spacing-result-txt {
    margin: 30px 0px 10px 0px !important;
  }

  .travel-payment-wrap .align-start-top {
    gap: 20px;
  }

  .car-tabs-features {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .inner-newslwtter-wrapper h2 {
    font-size: 20px;
  }

  .newsletter-form input {
    padding: 10px 30px;
  }

}

@media (max-width:768px) {
  .bottom_banner_img img {
    height: 126px;
  }

  .hotel-details-wrapper .nav-tabs li.nav-item .nav-link {
    padding: 15px 22px;
    font-size: 16px;
  }

  .destination-box.first-box.button-last-box {
    grid-column: 1/4;
  }

  .grid-wrapper-form {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .details-room-srch.booking-tab-form .destination-box.first-box input,
  .details-room-srch.booking-tab-form .destination-box.first-box select {
    padding: 12px 30px 12px 54px;
    line-height: 12px;
  }

  .related_rooms_wrapper .realted_inner {
    margin-bottom: 15px;
  }

  .select-col-spacing {
    margin-bottom: 10px;
  }

  .Selected_rooms {
    padding: 27px 0px 0px;
  }

  .mobile-hotel-details-banner {
    display: block !important;
    width: 100%;
    overflow: hidden;
  }

  .hotel-details-banner-inner-bg {
    display: none;
  }

  .mobile-hotel-details-banner button.slick-arrow.slick-prev {
    top: 40%;
  }

  .mobile-hotel-details-banner img {
    height: 230px;
    object-fit: cover;
  }

  .top-home-detail-wrapper.tab-description,
  .hotel_bottm_detail {
    padding: 20px 0px;
  }

  .footer {
    padding: 80px 0px 280px 0px;
  }

  .mobile-hotel-details-banner .slick-slider.slick-initialized {
    padding: 0px !important;
  }

  .booking-tab-form .destination-box input.form-control,
  .booking-tab-form .destination-box select {
    padding: 15px 20px 15px 45px !important;
  }

  html[dir="rtl"] .booking-tab-form .destination-box input.form-control,
  html[dir="rtl"] .booking-tab-form .destination-box select {
    padding: 15px 45px 15px 20px !important;
  }

}

@media (max-width:767px) {
  .p-lg {
    font-size: 14px;
  }

  .inner-newslwtter-wrapper {
    padding: 18px 37px !important;
    background-size: unset;
  }

  .inner-newslwtter-wrapper h3 {
    font-size: 18px;
  }

  .inner-newslwtter-wrapper p {
    font-size: 12px;
  }

  .newsletter-form input {
    padding: 7px 20px;
  }

  .footer-grid-wrap {
    grid-template-columns: 1fr 1fr;
  }

  .footer {
    padding: 20px 0px 250px 0px !important;
  }

  .booking-form-grid {
    grid-template-columns: 1fr !important;
    gap: 6px;
    position: unset;
    width: 100%;
  }

  .booking-flight-inner {
    padding: 25px 0px 8px;
  }

  .destination-box.second-box.marrgin-left,
  .destination-box.second-box.marrgin-rightm,
  .booking-tab-form .destination-box.search-btn {
    margin: 0px;
  }

  /* .booking-tab-form .destination-box.first-box input,
  .booking-tab-form .destination-box.first-box select,
  .booking-tab-form .destination-box.second-box input,
  .booking-tab-form .destination-box input,
  .booking-tab-form .destination-box select {
    padding: 12px 30px 12px 57px !important;
    line-height: 20px;
  } */

  .booking-flight-form .booking-tab-form .booking-form-grid .btn-gradient-purple {
    padding: 10px 30px !important;
  }

  .flight-tab-spacer {
    padding-bottom: 16px !important;
  }

  .inner-banner-wrapper .banner_time img {
    position: unset;
    width: 120px;
  }

  .tabs-flight .tabs-btn {
    padding: 12px 20px;
  }

  .section-padding,
  .payment-details-wrapper,
  .card-details-wrapper {
    padding: 20px 0px;
  }

  .card-details-wrapper .policies_box_inner,
  .card-details-wrapper .policies_box_inner ul {
    margin-bottom: 0px;
  }

  .ryt-room-select-price {
    margin-top: 15px;
  }

  /* .offer-card-box .card {
    margin: 0px;
  } */

  .offer-card-box h5 {
    font-size: 15px;
    line-height: 20.48px;
  }

  .ryt-travel-box img {
    width: 40%;
  }

  /* .destination-box.search-btn {
    grid-column: 1/3;
  } */

  body h1 {
    font-size: 35px;
  }

  body h2 {
    font-size: 23px;
  }

  .footer-logo-box {
    grid-column: 1/3;
  }

  .grid-wrapper-form {
    grid-template-columns: 1fr;
  }

  .destination-box.first-box.button-last-box {
    grid-column: unset;
  }

  .hotel-details-banner .row {
    width: 100%;
    margin: 0 auto;
  }

  /* .hotel-details-banner img {
    margin: 0px 0px 10px;
    width: 100%;
    height: 380px;
    object-fit: cover;
  } */

  .hotel-details-banner .container-fluid,
  .spacing-ryt,
  .spacing-ryt-lg,
  .hotel_bottm_detail {
    padding: 0px;
  }

  .Selected_rooms {
    margin: 0px;
    padding: 10px 0px 0px;
  }

  body h4 {
    font-size: 20px;
  }

  .top-related_rooms_wrapper,
  .related_rooms_wrapper .slick-slide,
  .related_rooms_wrapper .slick-slider.slick-initialized {
    padding: 0px;
  }

  .policies-wrapper {
    padding: 20px 0px 5px;
  }

  .details-room-srch {
    padding: 20px 0px 20px;
  }

  .hotel-details-wrapper .nav-tabs li.nav-item .nav-link {
    padding: 8px 12px !important;
  }

  /* .offer-card-box .card {
    margin: 0px 12px;
  } */

  /* .tab-grid-onewy {
    grid-column: 1/3;
  } */

  .booking-form-grid.flight-oneway-grid {
    grid-template-columns: 1fr !important;
  }

  /* .tab-grid-onewy .destination-box:nth-child(1) {
    margin-bottom: 6px !important;
  } */

  .tab-grid-onewy .destination-box {
    margin-bottom: 0px !important;
  }

  .booking-tab-form .booking-form-grid.round-trip-wrap-form .destination-box {
    grid-column: 1/3;
  }

  .inner-banner-wrapper {
    position: relative;
    padding: 12px 0px 8px;
  }

  .trending-box-content {
    margin: 0;
  }

  .top-home-detail-wrapper.tab-description,
  .booking-confirm-wrapper {
    padding: 20px 0px;
  }

  .amnenities-wrapper {
    margin: 15px 0px 0px;
  }

  .hotel-details-wrapper .page-id-tabs {
    padding: 10px 9px;
    font-size: 15px;
  }

  .select-col-spacing {
    margin: 0px;
    padding: 0px !important;
  }

  .select-room_inner img {
    width: 80px;
  }

  .related_rooms_wrapper h2 {
    margin-bottom: 0px !important;
    padding-top: 10px;
  }

  .related_rooms_wrapper .realted_inner {
    margin: 15px 0px;
  }

  .related_rooms_wrapper button.slick-arrow.slick-prev,
  .offer-card-wrapper button.slick-arrow.slick-prev,
  .trending-car-wrapper button.slick-arrow.slick-prev {
    top: -28px;
    width: 25px;
    height: 25px;
  }

  .related_rooms_wrapper button.slick-arrow.slick-prev::before,
  .offer-card-wrapper button.slick-arrow.slick-prev::before,
  .trending-car-wrapper button.slick-arrow.slick-prev::before {
    line-height: 20px;
    font-size: 15px;
  }

  .related_rooms_wrapper button.slick-arrow.slick-next,
  .offer-card-wrapper button.slick-arrow.slick-next,
  .trending-car-wrapper button.slick-arrow.slick-next {
    right: 10px;
    top: -15px;
    width: 25px;
    height: 25px;
  }

  .related_rooms_wrapper button.slick-arrow.slick-next::before,
  .offer-card-wrapper button.slick-arrow.slick-next::before,
  .trending-car-wrapper button.slick-arrow.slick-next::before {
    line-height: 10px;
    font-size: 15px;
  }

  .booking-btns {
    padding: 10px 40px !important;
  }

  .booking-btns-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
  }

  .booking-btns {
    width: 48% !important;
  }

  .confirm-price-box {
    padding: 25px 20px;
  }

  .grid-resultshow {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .result-img-rounded img {
    height: 240px;
    width: 100%;
    object-position: top;
  }

  .ryt-resultshow-wrapper .spacing-result-txt {
    margin: 30px 0px 13px 0px !important;
  }

  .inner-srch-spacing {
    padding: 0px !important;
  }

  .srch-price-box {
    border: 0px !important;
    margin: 0px;
  }

  .related_rooms_wrapper .price-room p b,
  .yellow-big-txt {
    font-size: 25px;
  }

  .room-feature ul {
    margin-bottom: 0px;
  }

  .srch-result-wrapper {
    padding: 33px 0px 0px;
  }

  .booking-flight-form {
    padding: 18px 0px 8px;
  }

  .box-list-details-wrap li {
    line-height: 32px;
  }

  .p-large {
    font-size: 14px;
  }

  .tour-details-timming {
    margin-top: 15px;
  }

  .orange-box-price {
    padding: 10px 14px;
  }

  .booking-btns-wrapper .booking-btns {
    width: 48% !important;
  }

  /* .grid-wrapper-form {
    grid-template-columns: 18% 18% 1fr 27%;
  } */

  .d-grid-calender {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .travel-contact-detail {
    padding-bottom: 0px !important;
  }

  .flight-time-box {
    grid-template-columns: 1fr 51% 1fr;
  }

  /* 
  .dotted-flight-box .flight-bottom-txt {
    display: block !important;
  } */

  .flight-inner-wrapper {
    grid-template-columns: 1fr;
    padding: 0px;
  }

  /* .ryt-filter .grid-resultshow-Flight {
    grid-template-columns: 65% 31%;
  } */

  body h5 {
    font-size: 15px;
  }

  .car-content-wrapper .car-inner-grid img {
    margin: 0 auto;
  }

  .car-tabs-features {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .policy-mb-space {
    padding: 0px;
  }

  .booking-tab-form .destination-box img {
    left: 3%;
  }

  html[dir="rtl"] .booking-tab-form .destination-box img {
    right: 3%;
  }

  .booking-tab-form .destination-box input[type="date"]::-webkit-calendar-picker-indicator {
    left: 12px;
  }

  .features-wrapper {
    padding: 25px 0px 10px;
  }

  .booking-tab-form .destination-box input.form-control,
  .booking-tab-form .destination-box select {
    padding: 10px 20px 10px 45px !important;
  }

  html[dir="rtl"] .booking-tab-form .destination-box input.form-control,
  html[dir="rtl"] .booking-tab-form .destination-box select {
    padding: 10px 45px 10px 20px !important;
  }

  .top-cities {
    padding: 0px 0px 8px;
  }

  .top-cities .card-hotel-wrapper,
  .trending-box-content {
    margin-bottom: 15px;
  }

  .where-to-travel-img-col {
    order: 1;
  }

  .where-to-travel-content-col {
    order: 2;
    margin-top: 20px;
  }

  .where-to-travel-wrappee {
    margin: 20px 0;
  }

  .top-hotels::before {
    display: none;
  }

  .left-travel-box {
    margin-top: 20px;
  }

  .login-signup-btn-grp {
    position: unset;
    flex-direction: row;
    transform: unset;
    gap: 20px;
    justify-content: center;
    margin-bottom: 20px;
  }

  .login-signup-btn-grp .btn {
    border-radius: 20px;
    padding: 15px 30px;
    font-size: 18px;
    border-width: 1px;
  }

  .login-signup-btn-grp .btn img {
    width: 40px;
  }

  .ryt-filter .grid-resultshow-Flight {
    padding: 15px;
  }

  .destination-box.second-box .react-datepicker-wrapper {
    width: 100%;
  }

  .roundedFlights-filters .flight-book-time-wrapper {
    grid-column: 1 / 1;
  }

  .return-heading-wrap h5 {
    margin-top: 10px;
  }

  .roundedFlights-filters .flight-inner-wrapper {
    margin-bottom: 12px;
  }

  .ryt-filter.roundedFlights-filters .grid-resultshow-Flight {
    gap: 0px;
  }

  .fight-sort-by-wrapper {
    width: max-content;
  }

  .car-contact-details-wrapper .travel-contact-detail {
    padding-top: 0px !important;
  }

  .car-contact-details-wrapper.py-5 {
    padding: 25px 0 !important;
  }

  .booking-form-grid .destination-box .ant-picker.ant-picker-range {
    padding: 10px 20px 10px 45px !important;
  }

  .show_results-filter-wrap {
    width: 45%;
  }

  .booking-tab-form .details_bg_lyt .destination-box .form-control {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .details-room-srch .details_bg_lyt {
    padding: 20px 10px 25px;
  }

  .card-hotel-wrapper .card-content {
    width: 90%;
  }

  .card-hotel-wrapper .reviews-box {
    gap: 5px !important;
  }

  .dashboard-booking-details-row.row-gap-5 {
    row-gap: 20px !important;
  }

  .border-solid-d {
    padding: 20px;
  }

  .admin-booking-detail-containers {
    grid-template-columns: 1fr 1fr;
  }

  .admin_pannel .admin-sidebar-toggle-wrap {
    position: relative;
    top: 0px;
  }

  .admin_pannel .admin-right-sidebar-wrapper.open .admin-sidebar-toggle-wrap {
    left: 250px;
  }
}

@media (max-width:576px) {
  .footer .copyryt-row-grid {
    display: block !important;
  }

  .inner-newslwtter-wrapper {
    background-image: none;
    background: linear-gradient(101.07deg, #6D338A 10.28%, #9671A9 41.91%, #5A2A72 96.03%);
  }

  .inner-newslwtter-wrapper {
    padding: 20px !important;
  }

  .booking-form-grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .header .desktop_navbar_wrap {
    gap: 12px !important;
  }

  .currency-translater-header .dropdown-mine {
    font-size: 12px;
  }

  .header .navbar-brand img {
    width: 95px;
  }

  .language-translater .active-language {
    padding: 2px 10px;
    font-size: 10px;
    line-height: 18px;
  }

  .header .navbar .navbar-toggler .navbar-toggler-icon {
    width: 22px;
  }

  .header .navbar .navbar-toggler {
    background: #fff;
    padding: 4px 10px;
  }

  .footer .copyryt-row-grid .footer-col-two {
    text-align: left !important;
    margin-top: 10px;
  }

  .footer-links li a {
    margin: 0px 10px 0px 0px;
  }

  .mobile-login-signup {
    margin-right: 6px;
  }

  .header .desktop_navbar_wrap {
    gap: 10px !important;
  }

  .inner-banner-wrapper h1 {
    font-size: 25px;
  }

  .inner-banner-wrapper p {
    font-size: 14px;
  }

  .navbar-tabs-mine,
  .booking-flight-inner .tabs-flight .nav-link {
    display: inline-block;
    margin: 0px;
  }

  #nav-hotel-tab,
  #nav-carrent-tab {
    border-radius: 0px !important;
  }

  .booking-tab-form .destination-box img {
    left: 12px;
  }

  html[dir="rtl"] .booking-tab-form .destination-box img {
    right: 12px;
  }

  /* .booking-form-grid {
    display: block;
  } */

  .destination-box.second-box.marrgin-right {
    margin-right: 0px;
  }

  /* .booking-tab-form .destination-box,
  .destination-box.second-box {
    margin-bottom: 10px !important;
  } */

  .trending-box-content {
    margin: 10px 0px 0px 0px;
  }

  .related_rooms_wrapper .slick-slide {
    padding: 0px;
  }

  .where-to-travel-wrappee .mobile_margin_add {
    margin-bottom: 0px !important;
  }

  .header-menus-wrap {
    top: calc(0% - -70px);
    height: auto;
    width: 100%;
  }

  .footer {
    padding: 30px 0px 160px 0px !important;
  }

  .ryt-travel-box img {
    width: 60%;
  }

  .amnenities-wrapper .list-box-amenities li {
    width: 50%;
  }

  .booking-flight-inner .tabs-flight .nav-link {
    width: 50%;
    background: #bdbdbd4d;
    border: 1px solid #ffffff21 !important;
  }

  .amnenities-wrapper .list-box-amenities li p {
    font-size: 13px;
  }

  .payment-details-wrapper {
    padding: 20px 0px;
  }

  .left-fill-details h4 {
    font-size: 20px;
  }

  .top-cities .slick-slide,
  .top-hotels-slider-wrap .slick-slide,
  .offer-card-wrapper .slick-slide,
  .trending-car-wrapper .slick-slide {
    padding: 0px !important;
  }

  .font-size-heading {
    font-size: 22px;
  }

  .font-size-heading img {
    width: 27px;
  }

  .currency_txt {
    font-size: 0px;
  }

  .translator_list .mobile-toggle-spacing {
    padding-right: 0px !important;
    font-size: 10px;
  }

  .currency-translater-header .dropdown-mine::after {
    width: 8px;
    height: 7px;
    margin-left: 2px;
  }

  .travel-payment-wrap .align-start-top {
    align-items: start !important;
    gap: 10px;
  }

  .ryt-filter .grid-resultshow-Flight {
    grid-template-columns: 1fr;
  }

  .flight-inner-wrapper {
    padding: 0px;
  }

  .car-tabs-features {
    grid-template-columns: 1fr 1fr;
  }

  .footer-menu-link {
    word-break: break-all;
  }

  .slick-track {
    gap: 0;
  }

  .offer-card-wrapper .offer-card-box p {
    font-size: 14px;
    margin-bottom: 12px !important;
  }

  .related_rooms_wrapper button.slick-arrow.slick-prev,
  .offer-card-wrapper button.slick-arrow.slick-prev,
  .trending-car-wrapper button.slick-arrow.slick-prev,
  .top-cities button.slick-arrow.slick-prev,
  .top-hotels-slider-wrap button.slick-arrow.slick-prev {
    top: -35px;
    width: 22px;
    height: 22px;
  }

  .related_rooms_wrapper button.slick-arrow.slick-next,
  .offer-card-wrapper button.slick-arrow.slick-next,
  .trending-car-wrapper button.slick-arrow.slick-next,
  .top-cities button.slick-arrow.slick-next,
  .top-hotels-slider-wrap button.slick-arrow.slick-next {
    top: -23px;
    width: 22px;
    height: 22px;
    right: 15px;
  }

  .top-cities button.slick-arrow.slick-next::before {
    line-height: normal;
  }

  .related_rooms_wrapper button.slick-arrow.slick-next::before,
  .offer-card-wrapper button.slick-arrow.slick-next::before,
  .trending-car-wrapper button.slick-arrow.slick-next::before {
    line-height: 7px;
    font-size: 14px;
  }

  .related_rooms_wrapper button.slick-arrow.slick-prev::before,
  .offer-card-wrapper button.slick-arrow.slick-prev::before,
  .trending-car-wrapper button.slick-arrow.slick-prev::before {
    line-height: 0px;
    font-size: 14px;
  }

  .footer-col-one {
    text-align: center;
  }

  .footer-col-two ul {
    justify-content: center;
  }

  .language-translater-dropdown .dropdown-mine {
    display: flex;
  }

  .language-translater-dropdown .dropdown-mine img {
    display: block;
  }

  .currency-translater-dropdown .dropdown-mine i {
    font-size: 15px;
  }

  .mobile-translator {
    display: inline-flex !important;
    align-items: center;
  }

  .top-hotels,
  .top-cities,
  .offers {
    margin-bottom: 20px;
  }

  .features-wrapper {
    padding: 30px 0px 12px 0;
  }

  .booking-flight-inner .tabs-flight .nav-link.active {
    border-radius: 0px !important;
  }

  .login-signup-btn-grp .btn {
    padding: 12px 25px;
    font-size: 18px;
    border-radius: 10px;
  }

  .login-signup-btn-grp .btn img {
    width: 30px;
  }

  .login-form-wrap {
    padding: 40px 20px 30px 20px;
  }

  .login-form-wrap h1 {
    font-size: 30px;
  }

  .remember-forgot-text label,
  .remember-forgot-text a {
    font-size: 14px;
  }

  .my-4.social-login-icons-wrap {
    margin: 20px 0px !important;
  }

  .mb-3.or-text {
    margin-bottom: 10px !important;
  }

  .copyright-wrapper {
    margin-top: 10px;
  }

  .flight-time-box {
    grid-template-columns: 1fr;
    gap: 0px;
  }

  .flight-time-box h5,
  .flight-time-box p {
    text-align: left !important;
  }

  .flight-book-time-wrapper .dotted-flight-box {
    margin: 5px 0px 15px 0px;
    order: 3;
    grid-column: 1 / 4;
  }

  .economy-label-wrapper {
    margin-left: 0;
  }

  .disparture-return-tabs .nav-link {
    padding: 14px 40px;
  }

  .gap-travel-between {
    flex-wrap: wrap;
    gap: 12px;
  }

  .admin-booking-detail-containers {
    gap: 12px;
    margin-bottom: 20px;
  }

  .total-booking-container-wrap,
  .cancel-booking-container-wrap,
  .completed-booking-container-wrap,
  .processing-booking-container-wrap {
    width: 100%;
    padding: 20px 20px;
  }

  .admin-right-sidebar-wrapper {
    padding: 30px 10px;
  }

  .table-header-wrapper {
    padding: 18px 16px;
  }

  .table-header-wrapper h5,
  .table-header-wrapper a {
    font-size: 14px;
  }

  .upcoming-list-table-wrapper thead tr th {
    font-size: 14px;
  }

  .status-label span {
    font-size: 13px;
  }

  .total-booking-container-wrap h1,
  .cancel-booking-container-wrap h1,
  .completed-booking-container-wrap h1,
  .processing-booking-container-wrap h1 {
    font-size: 28px;
    margin-bottom: 0px;
  }

  .admin_pannel .modal-dialog {
    margin-left: 10px;
    margin-right: 10px;
  }

  .customer-details-label span {
    display: block;
    font-size: 15px;
  }

  .table-header-wrapper .tabs-btn {
    font-size: 15px;
    padding: 10px 16px;
  }

  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
    flex-wrap: wrap;
  }

  .ant-picker-dropdown {
    margin: 0 auto;
    width: 90%;
    left: 50% !important;
    transform: translateX(-50%);
    min-width: auto !important;
  }

  .ant-picker-date-panel {
    width: 100% !important;
  }

  .show_results-filter-wrap {
    width: 100%;
  }

  .booking-detail-header-btns .add-review-btn.btn-gradient-purple,
  .booking-detail-header-btns .download-btn.btn-gradient-purple {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media screen and (max-width:400px) {
  .mobile-login-signup .user_btn {
    padding: 5px 10px;
  }

  .mobile-login-signup {
    margin-right: 0px;
  }

  .navbar a.navbar-brand {
    width: 100px;
    margin-right: 0;
  }

  .header .navbar .navbar-toggler {
    padding: 0px 5px;
  }
}

.slider-image-container {
  position: relative;
  width: 100%;
  /* Full width */
  padding-top: 56.25%;
  /* Aspect ratio 16:9 */
  overflow: hidden;
}

.slider-image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensures images are cropped to fit */
}

.disabled-btn {
  background: linear-gradient(180deg, grey 0%, grey 100%) !important;
  color: #fff !important;
  width: 100%;
  height: 100%;
  font-size: 14px !important;
  padding: 10px 40px !important;
  transition: all .5s;
  box-shadow: 0px -0.3px 0px 1px grey;
  border-radius: 5px;
}

.booking-detail-header-btns .add-review-btn.btn-review-disabled {
  background: transparent !important;
  color: #6d338a !important;
  width: 100%;
  height: 100%;
  font-size: 14px !important;
  padding: 10px 40px !important;
  transition: all .5s;
  box-shadow: 0px -0.3px 0px 1px #6d338a;
  border-radius: 5px;
}

.booking-detail-header-btns .add-review-btn.btn-review-active {
  background: linear-gradient(180deg, #8740AB 0%, #49225C 100%) !important;
  color: #fff !important;
  width: 100%;
  height: 100%;
  font-size: 14px !important;
  padding: 10px 40px !important;
  transition: all .5s;
  box-shadow: 0px -0.3px 0px 1px #6d338a;
  border-radius: 5px;
}